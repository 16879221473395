
  .detail-content {
      min-height: 100vh;
      padding-left: 0rem;
      padding-right: 0rem;
      @media (min-width:1240px) {
        padding: 0 10rem;
      }
    
    .card-img-detail {
      padding: 1.5rem;
      border-radius: 1.5rem;
      img {
        width: 100%;
      }
    }

    .card-info {
      padding: 1.5rem 0 1.5rem 1.5rem;

      .info-top {
        .card-title {
          font-family: HarmonyOS_Sans_Bold;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.5rem;
          color: #FFFFFF;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          height: 1.5rem;
          padding-bottom: 2rem;
        }

        .card-cols-2 {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-family: HarmonyOS_Sans_Bold;
          font-size: 1.5rem;
          font-weight: bold;
          line-height: 1.5rem;

          .color-title {
            background: linear-gradient(103deg, #EC0F00 0%, #FCDC66 99%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }

          .white-title {
            font-family: HarmonyOS_Sans_Thin;
            font-size: 1.5rem;
            font-weight: normal;
            line-height: 1.5rem;
          }

          .attention-number {
            display: flex;
            align-items: center;
            font-family: HarmonyOS_Sans_Thin;
            font-size: 1rem;
            line-height: 1rem;
            letter-spacing: 0px;
            color: #FFFFFF;
            
            
            span {
              font-family: HarmonyOS_Sans_Bold;
              font-weight: bold;
              font-size: 1.5rem;
            }

            .lable-button {
              padding-left: 1rem;
              span {
                font-size: 1rem;
              }
            }

            .count{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .inputGroup{
              text-align: center;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              button{
                &:disabled {
                  cursor:not-allowed;
                  background-color: #eee;
                  color: #6D6D6D;
                }
              }
              input{
                &:disabled{
                  cursor:not-allowed;
                  // background: #6D6D6D!important;
                  -webkit-text-fill-color: #6D6D6D!important;
                  color: #6D6D6D!important;
                  // background-clip: ;
                }
              }
            }

            .inputGroup button{
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: .12rem;
                color: rgba(0, 0, 0, 0.6);
                background-color: #fff;
                font-size: 1.5rem;
                font-family: HarmonyOS_Sans_Bold;
                border-radius: 0.25rem;
            }

            .inputGroup .cont-input {
              caret-color: #EC0F00;
              margin: 0 0.5rem;
              background-color: #FFFFFF;
              input{
                min-width: 1px;
                width: 5.75rem;
                height: 2.5rem;
                font-family: DDBFont;
                text-align: center;
                font-family: HarmonyOS_Sans_Bold;
                font-size: 1.5rem;
                color: rgba(255, 255, 255, 0.5);
                background: linear-gradient(103deg, #EC0F00 0%, #FCDC66 99%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                background-color: rgb(85, 28, 28);
                border: .01rem solid #ffffff71;
                position: relative;
                
              
                // pointer-events: none;
                border-radius: 0.25rem;
                &:focus,&:hover,&:active{
                border: .01rem solid #ffffff71;
                }
            }
            }

            .inputGroup input:focus{
                border: none;
                outline: none;
            }
          }

          .detail-buy-button {
            width: 100%;
            button {
              width: 100%;
              height: 5.437rem;
              border-radius: 3.187rem;
              font-family: HarmonyOS_Sans_Bold;
              font-size: 2rem;
              font-weight: bold;
              line-height: 2rem;
              text-align: center;
            }
          }
        }
      }
    }

    

    .share-twitter {
      .detail-buy-button {
        width: 100%;
        button {
          width: 100%;
          height: 5.437rem;
          border-radius: 3.187rem;
          font-family: HarmonyOS_Sans_Bold;
          font-size: 2rem;
          font-weight: bold;
          line-height: 2rem;
          text-align: center;
          border: 2px solid #4D5882;
        }
      }

      .detail-share-twitter-button {
        button {
          width: 100%;
          height: 4.875rem;
          border-radius: 2.437rem;
          font-family: HarmonyOS_Sans_Bold;
          font-size: 0.875rem;
          font-weight: bold;
          border: 2px solid #4D5882;
        }
      }

      .detail-copy-button {
        a {
          width: 100%;
          height: 3rem;
          border-radius: 2.437rem;
          font-family: HarmonyOS_Sans_Thin;
          font-size: 0.875rem;
          font-weight: bold;
          border: 2px solid #4D5882;
        }
      }

      .end-soon-detail {
        font-family: HarmonyOS_Sans_Bold;
        font-size: 2.125rem;
        font-weight: bold;
        line-height: 2.125rem;
        padding-bottom: 2.25rem;
      }
    }
  }


  .chooseWinner{
    margin-top: 4.4rem;
    img{
      width: 3rem;
      margin-bottom: 3rem;
    }
    .color-title {
      background: linear-gradient(103deg, #EC0F00 0%, #FCDC66 99%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: center;
      font-size: 2rem;
      margin-bottom: 4.4rem;
    }
  }

  .winner_info{
    padding: 4rem 0;
    h1{
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .wineer{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
      img{
        width: 7rem;
        margin: 0 2rem;
      }
      .default_img{
        width: 7rem;
        height: 7rem;
        margin: 0 2rem;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #fff;
      }
      span{
        flex: 1;
      }
    }

  }

  .hyperlink{
    text-align: center;
    padding: 1.75rem;
    font-size: 2rem;
    background-color: #3A8AFF;
    border-radius: 3.2rem;
  }


  .chossing-await{
    text-align: center;
    padding: 1.75rem;
    font-size: 2rem;
    border-radius: 3.2rem;
    background: linear-gradient(97deg, #EC0F00 0%, #FCDC66 100%);
  }