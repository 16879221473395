// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;

//   &:before,
//   &:after {
//     box-sizing: inherit;
//   }
// }

// a {
//   text-decoration: none;
//   out.nav-main .router-link-active.nav-main-link span:beforeline: none;
//   color: inherit;
//   cursor: pointer;

//   img {
//     vertical-align: top;
//   }
// }

// img {
//   border: none;
// }

// ul,
// li {
//   list-style: none;
// }

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

// html {
//   text-size-adjust: 100%;
// }

// body {
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-rendering: optimizeLegibility;
// }



// @font-face {
//   font-display:block;
//   font-family:icomoon;
//   font-style:normal;
//   font-weight:400;
//   src:url(../../asstes/fonts/icomoon.woff) format("woff")
// }
// [class*=" icon-"], [class^=icon-] {
//   speak:never;
//   -webkit-font-smoothing:antialiased;
//   -moz-osx-font-smoothing:grayscale;
//   font-family:icomoon!important;
//   font-style:normal;
//   font-variant:normal;
//   font-weight:400;
//   line-height:1;
//   text-transform:none
// }
// .icon-ico-carret-left:before {
//   content:"\e92e"
// }
// .icon-ico-carret-right:before {
//   content:"\e92f"
// }
// .icon-ico-user-list:before {
//   content:"\e929"
// }
// .icon-ico-timer:before {
//   content:"\e92a"
// }
// .icon-ico-clock-clockwise:before {
//   content:"\e92b"
// }
// .icon-ico-wallet:before {
//   content:"\e92c"
// }
// .icon-ico-user:before {
//   content:"\e92d"
// }
// .icon-ico-speaker-disabled:before {
//   content:"\e923"
// }
// .icon-ico-speaker:before {
//   content:"\e928"
// }
// .icon-ico-whatsapp:before {
//   content:"\e925"
// }
// .icon-ico-copy:before {
//   content:"\e926"
// }
// .icon-ico-user-add:before {
//   content:"\e927"
// }
// .icon-ico-resize-down:before {
//   content:"\e924"
// }
// .icon-ico-resize-up:before {
//   content:"\e922"
// }
// .icon-ico-refresh:before {
//   content:"\e921"
// }
// .icon-ico-eye:before {
//   content:"\e91b"
// }
// .icon-ico-target:before {
//   content:"\e91c"
// }
// .icon-ico-thunder:before {
//   content:"\e91d"
// }
// .icon-ico-globe:before {
//   content:"\e91e"
// }
// .icon-ico-bookmark:before {
//   content:"\e91f"
// }
// .icon-ico-processor:before {
//   content:"\e920"
// }
// .icon-ico-sort-arrows:before {
//   content:"\e919"
// }
// .icon-ico-funnel:before {
//   content:"\e91a"
// }
// .icon-ico-logout:before {
//   content:"\e918"
// }
// .icon-ico-account:before {
//   content:"\e913"
// }
// .icon-ico-settings:before {
//   content:"\e915"
// }
// .icon-ico-share:before {
//   content:"\e911"
// }
// .icon-ico-hourglass:before {
//   content:"\e910"
// }
// .icon-ico-padlock:before {
//   content:"\e90c"
// }
// .icon-ico-link-external:before {
//   content:"\e90f"
// }
// .icon-ico-chat:before {
//   content:"\e90e"
// }
// .icon-ico-trophy:before {
//   content:"\e902"
// }
// .icon-ico-facebook:before {
//   content:"\e90d"
// }
// .icon-ico-badge:before {
//   content:"\e906"
// }
// .icon-ico-ticket:before {
//   content:"\e901"
// }
// .icon-ico-down-pointer:before {
//   content:"\e909"
// }
// .icon-ico-up-pointer:before {
//   content:"\e908"
// }
// .icon-ico-bell:before {
//   content:"\e907"
// }
// .icon-ico-plus:before {
//   content:"\e903"
// }
// .icon-ico-edit:before {
//   content:"\e904"
// }
// .icon-ico-eth:before {
//   content:"\e905"
// }
// .icon-ico-cart:before {
//   content:"\e90a"
// }
// .icon-ico-tick:before {
//   content:"\e900"
// }
// .icon-ico-discord:before {
//   content:"\e90b"
// }
// .icon-ico-instagram:before {
//   content:"\e912"
// }
// .icon-ico-send:before {
//   content:"\e914"
// }
// .icon-ico-twitter:before {
//   content:"\e916"
// }
// .icon-ico-x:before {
//   content:"\e917"
// }

// @font-face {
//   font-display:block;
//   font-family:icomoon2;
//   font-style:normal;
//   font-weight:400;
//   src:url(../../asstes/fonts/icomoon_add.woff) format("woff")
// }
// [class*=" icon2-"], [class^=icon2-] {
//   speak:never;
//   -webkit-font-smoothing:antialiased;
//   -moz-osx-font-smoothing:grayscale;
//   font-family:icomoon2!important;
//   font-style:normal;
//   font-variant:normal;
//   font-weight:400;
//   line-height:1;
//   text-transform:none
// }

// .icon2-ico-badge:before {
//   content:"\e900"
// }
// .icon2-ico-eth:before {
//   content:"\e901"
// }
// .icon2-ico-success:before {
//   content:"\e907"
// }
// .icon2-ico-error:before {
//   content:"\e908"
// }
// .icon2-ico-share:before {
//   content:"\e906"
// }
// .icon2-ico-account:before {
//   content:"\e909"
// }
// .icon2-ico-logout:before {
//   content:"\e90a"
// }
// .icon2-ico-hourglass:before {
//   content:"\e90b"
// }


// @font-face {
//   font-display:block;
//   font-family:iconOhmynft;
//   font-style:normal;
//   font-weight:400;
//   src:url(../../asstes/icomoon/icomoon.woff) format("woff")
// }
// [class*=" oicon-"], [class^=oicon-] {
//   speak:never;
//   -webkit-font-smoothing:antialiased;
//   -moz-osx-font-smoothing:grayscale;
//   font-family:iconOhmynft!important;
//   font-style:normal;
//   font-variant:normal;
//   font-weight:400;
//   line-height:1;
//   text-transform:none
// }

// .oicon-ico-logout:before {
//   content:"\e900"
// }

// .oicon-ico-setting:before {
//   content:"\e901"
// }
// @font-face {
//   font-display:swap;
//   font-family:Play;
//   font-style:normal;
//   font-weight:400;
//   src:url(../../asstes/fonts/Play-Regular.woff) format("woff")
// }
// @font-face {
//   font-display:swap;
//   font-family:Play;
//   font-style:normal;
//   font-weight:700;
//   src:url(../../asstes/fonts/Play-Bold.woff) format("woff")
// }

@font-face {
  font-family:Play;
  src: url(../../asstes/fonts/ComicBookBold.otf) format("opentype");
}

@font-face {
  font-family:Avenir;
  src: url(../../asstes/fonts/Avenir/Avenir-Light-07.ttf);
}


@font-face {
  font-family:AvenirMedium;
  src: url(../../asstes/fonts/Avenir/Avenir-Medium-09.ttf);
}

@font-face {
  font-family:AvenirHeavy;
  src: url(../../asstes/fonts/Avenir/Avenir-Heavy-05.ttf);
}

@font-face {
  font-family:AvenirBlack;
  src: url(../../asstes/fonts/Avenir/Avenir-Black-03.ttf);
}




.font-play{
  font-family:Play;
}

.font-nft{
  font-family: HarmonyOS_Sans_Black;
  font-size: 1rem;
  font-size: small;
}

.font-thin {
  font-family: HarmonyOS_Sans_thin;
}

.font-Bold {
  font-family: HarmonyOS_Sans_Bold;
}

.font-Regular {
  font-family: HarmonyOS_Sans_Regular;
}

.font-Medium {
  font-family: HarmonyOS_Sans_Medium;
}

.font-medium{
  font-family:AvenirMedium;
}

.font-black{
  font-family:AvenirBlack;
}

.font-heavy{
  font-family:AvenirHeavy;
}


.page-enter-active, .page-leave-active {
  transition-duration:.2s;
  transition-property:opacity;
  transition-timing-function:ease
}
.page-enter, .page-leave-active {
  opacity:0
}
.layout-enter-active, .layout-leave-active {
  transition-duration:.2s;
  transition-property:opacity;
  transition-timing-function:ease
}
.layout-enter, .layout-leave-active {
  opacity:0
}
.fade-enter-active, .fade-leave-active {
  transition:opacity .3s ease
}
.fade-enter-from, .fade-leave-to {
  opacity:0
}
.fade-fast-enter-active, .fade-fast-leave-active {
  transition:opacity .15s ease
}
.fade-fast-enter-from, .fade-fast-leave-to {
  opacity:0
}
.slideup-fade-enter-active {
  transition:all .2s ease
}
.slideup-fade-leave-active {
  transition:all .2s cubic-bezier(1, .5, .8, 1)
}
.slideup-fade-enter, .slideup-fade-leave-to {
  opacity:0;
  transform:translateY(-15px)
}
.cardlist-enter-active, .cardlist-leave-active {
  transition:all .3s cubic-bezier(1, .5, .8, 1)
}
.cardlist-enter-from, .cardlist-leave-to {
  opacity:0;
  transform:translateY(5px)
}
.list-enter-active, .list-leave-active, .list-move {
  transition:all .2s cubic-bezier(1, .5, .8, 1)
}
.list-enter-from, .list-leave-to {
  opacity:0;
  transform:translate(10px)
}
.list-leave-active {
  position:absolute
}
.chat-desktop-enter-active, .chat-desktop-leave-active {
  transition:all .2s cubic-bezier(1, .5, .8, 1)
}
.chat-desktop-enter-from, .chat-desktop-leave-to {
  transform:translate(120%)
}
.chat-mobile-enter-active, .chat-mobile-leave-active {
  transition:all .2s cubic-bezier(1, .5, .8, 1)
}
.chat-mobile-enter-from, .chat-mobile-leave-to {
  transform:translateY(120%)
}
.tab-enter-active, .tab-leave-active {
  transition:all .2s
}
.tab-enter, .tab-leave-to {
  opacity:0
}
.tab-enter-active {
  transition-delay:1s
}
.nav-main .nav-main-link {
  border-bottom-width:4px;
  border-color:transparent
}
.nav-main .router-link-active.nav-main-link span {
  --tw-text-opacity:1;
  color:rgb(0 209 255/var(--tw-text-opacity));
  font-weight:700;
  position:relative
}
@media (min-width:1024px) {
  .nav-main .router-link-active.nav-main-link span {
      font-weight:400
  }
}
.nav-main .router-link-active.nav-main-link span:before {
  --tw-rotate:90deg;
  --tw-border-opacity:1;
  border-bottom:8px solid rgb(0 209 255/var(--tw-border-opacity));
  border-left:4px solid transparent;
  border-right:4px solid transparent;
  border-top-style:solid;
  border-top-width:0;
  content:"";
  left:-1rem;
  margin-top:-.25rem;
  position:absolute;
  top:50%;
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-duration:.15s;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}

.nav-main .route-link.router-link-active::after{
  // content: '';
  width: 50%;
  height: 5px;
  background-color: #2B93FF;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2.5px;
}
@media (min-width:1024px) {
  .nav-main .router-link-active.nav-main-link span:before {
      --tw-rotate:0deg;
      bottom:-.75rem;
      left:50%;
      margin-left:-.25rem;
      top:auto;
      transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
}
.nav-main .router-link-active.nav-main-link span:after {
  --tw-rotate:-90deg;
  --tw-border-opacity:1;
  border-bottom:8px solid rgb(0 209 255/var(--tw-border-opacity));
  border-left:4px solid transparent;
  border-right:4px solid transparent;
  border-top-style:solid;
  border-top-width:0;
  content:"";
  margin-top:-.25rem;
  position:absolute;
  right:-1rem;
  top:50%;
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@media (min-width:1024px) {
  .nav-main .router-link-active.nav-main-link span:after {
      display:none
  }
}
.nav-main .router-link-active.nav-main-link img {
  --tw-border-opacity:1;
  border-color:rgb(255 255 255/var(--tw-border-opacity))
}
.mask-gradient-radial {
  -webkit-mask-image:radial-gradient(ellipse 100% 100% at 50% 50%, #000 30%, transparent 50%);
  mask-image:radial-gradient(ellipse 100% 100% at 50% 50%, #000 30%, transparent 50%)
}
.mask-gradient-chat {
  -webkit-mask-image:linear-gradient(180deg, transparent 0, #000 10%);
  mask-image:linear-gradient(180deg, transparent 0, #000 10%)
}
.overflow-scrollbar-hidden {
  -ms-overflow-style:none;
  overflow-y:scroll;
  scrollbar-width:none
}
.overflow-scrollbar-hidden::-webkit-scrollbar {
  height:0;
  width:0
}
input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
  -webkit-text-fill-color:#fff!important;
  -webkit-box-shadow:inset 0 0 0 1000px #25313b!important
}
.content-visibility-visible {
  content-visibility:visible!important
}
.hardware-accelerate, .hardware-accelerate * {
  -webkit-transform:translateZ(0)
}
::-webkit-scrollbar {
  width:12px
}
::-webkit-scrollbar-track {
  box-shadow:inset 0 0 12px #000;
  width:10px
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity:1;
  background-color:rgb(55 65 81/var(--tw-bg-opacity));
  border:1px solid #000;
  border-radius:10px;
  box-shadow:inset 0 0 2px #000
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity:1;
  background-color:rgb(107 114 128/var(--tw-bg-opacity))
}
.scrollbar-hide::-webkit-scrollbar {
  display:none
}
.scrollbar-hide {
  -ms-overflow-style:none;
  scrollbar-width:none
}
input:disabled::-moz-placeholder, textarea:disabled::-moz-placeholder {
  -webkit-text-fill-color:#777e91;
  opacity:1
}
input:disabled, input:disabled::placeholder, textarea:disabled, textarea:disabled::placeholder {
  -webkit-text-fill-color:#777e91;
  opacity:1
}
* {
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color:transparent
}
.walletconnect-modal__mobile__toggle a {
  color:#000
}
*, :after, :before {
  border:0 solid #e5e7eb;
  box-sizing:border-box
}
:after, :before {
  --tw-content:""
}
html {
  -webkit-text-size-adjust:100%;
  font-family:HarmonyOS_Sans_Black, system-ui, sans-serif;
  line-height:1;
  -moz-tab-size:4;
  -o-tab-size:4;
  tab-size:4
}
body {
  line-height:inherit;
  margin:0
}
hr {
  border-top-width:1px;
  color:inherit;
  height:0
}
abbr:where([title]) {
  -webkit-text-decoration:underline dotted;
  text-decoration:underline dotted
}
h1, h2, h3, h4, h5, h6 {
  font-size:inherit;
  font-weight:inherit
}
a {
  color:inherit;
  text-decoration:inherit
}
b, strong {
  font-weight:bolder
}
code, kbd, pre, samp {
  font-family:ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size:1em
}
small {
  font-size:80%
}
sub, sup {
  font-size:75%;
  line-height:0;
  position:relative;
  vertical-align:baseline
}
sub {
  bottom:-.25em
}
sup {
  top:-.5em
}
table {
  border-collapse:collapse;
  border-color:inherit;
  text-indent:0
}
button, input, optgroup, select, textarea {
  color:inherit;
  font-family:inherit;
  font-size:100%;
  font-weight:inherit;
  line-height:inherit;
  margin:0;
  padding:0
}
button, select {
  text-transform:none
}
.button-background {
  background-color: #3a8aff;
}
[type=button], [type=reset], [type=submit], button {
  -webkit-appearance:button;
  // background-color:transparent;
  // background-image:none
}
:-moz-focusring {
  outline:auto
}
:-moz-ui-invalid {
  box-shadow:none
}
progress {
  vertical-align:baseline
}
::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height:auto
}
[type=search] {
  -webkit-appearance:textfield;
  outline-offset:-2px
}
::-webkit-search-decoration {
  -webkit-appearance:none
}
::-webkit-file-upload-button {
  -webkit-appearance:button;
  font:inherit
}
summary {
  display:list-item
}
blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre {
  margin:0
}
fieldset {
  margin:0
}
fieldset, legend {
  padding:0
}
menu, ol, ul {
  list-style:none;
  margin:0;
  padding:0
}
textarea {
  resize:vertical
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color:#9ca3af
}
input::placeholder, textarea::placeholder {
  color:#9ca3af
}
[role=button], button {
  cursor:pointer
}
:disabled {
  cursor:default
}
audio, canvas, embed, iframe, img, object, svg, video {
  display:block;
  vertical-align:middle
}
img, video {
  height:auto;
  max-width:100%
}
[hidden] {
  display:none
}
[multiple], [type=date], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=time], [type=url], [type=week], select, textarea {
  --tw-shadow:0 0 #0000;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  background-color:#fff;
  border-color:#6b7280;
  border-radius:0;
  border-width:1px;
  font-size:1rem;
  line-height:1.5rem;
  padding:.5rem .75rem
}
[multiple]:focus, [type=date]:focus, [type=datetime-local]:focus, [type=email]:focus, [type=month]:focus, [type=number]:focus, [type=password]:focus, [type=search]:focus, [type=tel]:focus, [type=text]:focus, [type=time]:focus, [type=url]:focus, [type=week]:focus, select:focus, textarea:focus {
  --tw-ring-inset:var(--tw-empty, );
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:#0068c7;
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color:#0068c7;
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color:#6b7280;
  opacity:1
}
input::placeholder, textarea::placeholder {
  color:#6b7280;
  opacity:1
}

input.code::-moz-placeholder {
  color:#fff;
  opacity:1
}
input.code::placeholder{
  color:#fff;
  opacity:1
}
::-webkit-datetime-edit-fields-wrapper {
  padding:0
}
::-webkit-date-and-time-value {
  min-height:1.5em
}
::-webkit-datetime-edit, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-meridiem-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-year-field {
  padding-bottom:0;
  padding-top:0
}
select {
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position:right .5rem center;
  background-repeat:no-repeat;
  background-size:1.5em 1.5em;
  padding-right:2.5rem;
  print-color-adjust:exact
}
[multiple] {
  background-image:none;
  background-position:0 0;
  background-repeat:unset;
  background-size:initial;
  padding-right:.75rem;
  print-color-adjust:unset
}
[type=checkbox], [type=radio] {
  --tw-shadow:0 0 #0000;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  background-color:#fff;
  background-origin:border-box;
  border-color:#6b7280;
  border-width:1px;
  color:#0068c7;
  display:inline-block;
  flex-shrink:0;
  height:1rem;
  padding:0;
  print-color-adjust:exact;
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none;
  vertical-align:middle;
  width:1rem
}
[type=checkbox] {
  border-radius:0
}
[type=radio] {
  border-radius:100%
}
[type=checkbox]:focus, [type=radio]:focus {
  --tw-ring-inset:var(--tw-empty, );
  --tw-ring-offset-width:2px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:#0068c7;
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline:2px solid transparent;
  outline-offset:2px
}
[type=checkbox]:checked, [type=radio]:checked {
  background-color:currentColor;
  background-position:50%;
  background-repeat:no-repeat;
  background-size:100% 100%;
  border-color:transparent
}
[type=checkbox]:checked {
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E")
}
[type=radio]:checked {
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E")
}
[type=checkbox]:checked:focus, [type=checkbox]:checked:hover, [type=radio]:checked:focus, [type=radio]:checked:hover {
  background-color:currentColor;
  border-color:transparent
}
[type=checkbox]:indeterminate {
  background-color:currentColor;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E");
  background-position:50%;
  background-repeat:no-repeat;
  background-size:100% 100%;
  border-color:transparent
}
[type=checkbox]:indeterminate:focus, [type=checkbox]:indeterminate:hover {
  background-color:currentColor;
  border-color:transparent
}
[type=file] {
  background:unset;
  border-color:inherit;
  border-radius:0;
  border-width:0;
  font-size:unset;
  line-height:inherit;
  padding:0
}
[type=file]:focus {
  outline:1px solid ButtonText;
  outline:1px auto -webkit-focus-ring-color
}
*, :after, :before {
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-scroll-snap-strictness:proximity;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgba(0, 133, 255, .5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
}
::backdrop {
  --tw-border-spacing-x:0;
  --tw-border-spacing-y:0;
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  --tw-scroll-snap-strictness:proximity;
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgba(0, 133, 255, .5);
  --tw-ring-offset-shadow:0 0 #0000;
  --tw-ring-shadow:0 0 #0000;
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000;
}
.container {
  width:96%;
  margin: auto;
}
@media (min-width:640px) {
  .container {
      max-width:640px
  }
}
@media (min-width:768px) {
  .container {
      max-width:768px
  }
}
@media (min-width:1024px) {
  .container {
      max-width:1024px
  }
}
@media (min-width:1280px) {
  .container {
      max-width:1280px
  }
}
@media (min-width:1520px) {
  .container {
      max-width:1520px
  }
}
@media (min-width:1800px) {
  .container {
      max-width:1800px
  }
}
@media (min-width:2100px) {
  .container {
      max-width:2100px
  }
}
@media (min-width:2600px) {
  .container {
      max-width:2600px
  }
}
.prose {
  color:var(--tw-prose-body);
  max-width:65ch
}
.prose :where([class~=lead]):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-lead);
  font-size:1.25em;
  line-height:1.6;
  margin-bottom:1.2em;
  margin-top:1.2em
}
.prose :where(a):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-links);
  font-weight:500;
  text-decoration:underline
}
.prose :where(strong):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-bold);
  font-weight:600
}
.prose :where(a strong):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(blockquote strong):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(thead th strong):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(ol):not(:where([class~=not-prose] *)) {
  list-style-type:decimal;
  margin-bottom:1.25em;
  margin-top:1.25em;
  padding-left:1.625em
}
.prose :where(ol[type=A]):not(:where([class~=not-prose] *)) {
  list-style-type:upper-alpha
}
.prose :where(ol[type=a]):not(:where([class~=not-prose] *)) {
  list-style-type:lower-alpha
}
.prose :where(ol[type=A s]):not(:where([class~=not-prose] *)) {
  list-style-type:upper-alpha
}
.prose :where(ol[type=a s]):not(:where([class~=not-prose] *)) {
  list-style-type:lower-alpha
}
.prose :where(ol[type=I]):not(:where([class~=not-prose] *)) {
  list-style-type:upper-roman
}
.prose :where(ol[type=i]):not(:where([class~=not-prose] *)) {
  list-style-type:lower-roman
}
.prose :where(ol[type=I s]):not(:where([class~=not-prose] *)) {
  list-style-type:upper-roman
}
.prose :where(ol[type=i s]):not(:where([class~=not-prose] *)) {
  list-style-type:lower-roman
}
.prose :where(ol[type="1"]):not(:where([class~=not-prose] *)) {
  list-style-type:decimal
}
.prose :where(ul):not(:where([class~=not-prose] *)) {
  list-style-type:disc;
  margin-bottom:1.25em;
  margin-top:1.25em;
  padding-left:1.625em
}
.prose :where(ol>li):not(:where([class~=not-prose] *))::marker {
  color:var(--tw-prose-counters);
  font-weight:400
}
.prose :where(ul>li):not(:where([class~=not-prose] *))::marker {
  color:var(--tw-prose-invert-headings)
}
.prose :where(hr):not(:where([class~=not-prose] *)) {
  border-color:var(--tw-prose-hr);
  border-top-width:1px;
  margin-bottom:3em;
  margin-top:3em
}
.prose :where(blockquote):not(:where([class~=not-prose] *)) {
  border-left-color:var(--tw-prose-quote-borders);
  border-left-width:.25rem;
  color:var(--tw-prose-quotes);
  font-style:italic;
  font-weight:500;
  margin-bottom:1.6em;
  margin-top:1.6em;
  padding-left:1em;
  // quotes:"\201c""\201d""\2018""\2019"
}
.prose :where(blockquote p:first-of-type):not(:where([class~=not-prose] *)):before {
  content:open-quote
}
.prose :where(blockquote p:last-of-type):not(:where([class~=not-prose] *)):after {
  content:close-quote
}
.prose :where(h1):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-headings);
  font-size:2.25em;
  font-weight:800;
  line-height:1.1111111;
  margin-bottom:.8888889em;
  margin-top:0
}
.prose :where(h1 strong):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-weight:900
}
.prose :where(h2):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-headings);
  font-size:1.5em;
  font-weight:700;
  line-height:1.3333333;
  margin-bottom:1em;
  margin-top:2em
}
.prose :where(h2 strong):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-weight:800
}
.prose :where(h3):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-headings);
  font-size:1.25em;
  font-weight:600;
  line-height:1.6;
  margin-bottom:.6em;
  margin-top:1.6em
}
.prose :where(h3 strong):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-weight:700
}
.prose :where(h4):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-headings);
  font-weight:600;
  line-height:1.5;
  margin-bottom:.5em;
  margin-top:1.5em
}
.prose :where(h4 strong):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-weight:700
}
.prose :where(img):not(:where([class~=not-prose] *)) {
  margin-bottom:2em;
  margin-top:2em
}
.prose :where(figure>*):not(:where([class~=not-prose] *)) {
  margin-bottom:0;
  margin-top:0
}
.prose :where(figcaption):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-captions);
  font-size:.875em;
  line-height:1.4285714;
  margin-top:.8571429em
}
.prose :where(code):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-code);
  font-size:.875em;
  font-weight:600
}
.prose :where(code):not(:where([class~=not-prose] *)):before {
  content:"`"
}
.prose :where(code):not(:where([class~=not-prose] *)):after {
  content:"`"
}
.prose :where(a code):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(h1 code):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(h2 code):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-size:.875em
}
.prose :where(h3 code):not(:where([class~=not-prose] *)) {
  color:inherit;
  font-size:.9em
}
.prose :where(h4 code):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(blockquote code):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(thead th code):not(:where([class~=not-prose] *)) {
  color:inherit
}
.prose :where(pre):not(:where([class~=not-prose] *)) {
  background-color:var(--tw-prose-pre-bg);
  border-radius:.375rem;
  color:var(--tw-prose-pre-code);
  font-size:.875em;
  font-weight:400;
  line-height:1.7142857;
  margin-bottom:1.7142857em;
  margin-top:1.7142857em;
  overflow-x:auto;
  padding:.8571429em 1.1428571em
}
.prose :where(pre code):not(:where([class~=not-prose] *)) {
  background-color:transparent;
  border-radius:0;
  border-width:0;
  color:inherit;
  font-family:inherit;
  font-size:inherit;
  font-weight:inherit;
  line-height:inherit;
  padding:0
}
.prose :where(pre code):not(:where([class~=not-prose] *)):before {
  content:none
}
.prose :where(pre code):not(:where([class~=not-prose] *)):after {
  content:none
}
.prose :where(table):not(:where([class~=not-prose] *)) {
  font-size:.875em;
  line-height:1.7142857;
  margin-bottom:2em;
  margin-top:2em;
  table-layout:auto;
  text-align:left;
  width:100%
}
.prose :where(thead):not(:where([class~=not-prose] *)) {
  border-bottom-color:var(--tw-prose-th-borders);
  border-bottom-width:1px
}
.prose :where(thead th):not(:where([class~=not-prose] *)) {
  color:var(--tw-prose-headings);
  font-weight:600;
  padding-bottom:.5714286em;
  padding-left:.5714286em;
  padding-right:.5714286em;
  vertical-align:bottom
}
.prose :where(tbody tr):not(:where([class~=not-prose] *)) {
  border-bottom-color:var(--tw-prose-td-borders);
  border-bottom-width:1px
}
.prose :where(tbody tr:last-child):not(:where([class~=not-prose] *)) {
  border-bottom-width:0
}
.prose :where(tbody td):not(:where([class~=not-prose] *)) {
  vertical-align:baseline
}
.prose :where(tfoot):not(:where([class~=not-prose] *)) {
  border-top-color:var(--tw-prose-th-borders);
  border-top-width:1px
}
.prose :where(tfoot td):not(:where([class~=not-prose] *)) {
  vertical-align:top
}
.prose {
  --tw-prose-body:#374151;
  --tw-prose-headings:#111827;
  --tw-prose-lead:#4b5563;
  --tw-prose-links:#111827;
  --tw-prose-bold:#111827;
  --tw-prose-counters:#6b7280;
  --tw-prose-bullets:#d1d5db;
  --tw-prose-hr:#e5e7eb;
  --tw-prose-quotes:#111827;
  --tw-prose-quote-borders:#e5e7eb;
  --tw-prose-captions:#6b7280;
  --tw-prose-code:#111827;
  --tw-prose-pre-code:#e5e7eb;
  --tw-prose-pre-bg:#1f2937;
  --tw-prose-th-borders:#d1d5db;
  --tw-prose-td-borders:#e5e7eb;
  --tw-prose-invert-body:#d1d5db;
  --tw-prose-invert-headings:#fff;
  --tw-prose-invert-lead:#9ca3af;
  --tw-prose-invert-links:#fff;
  --tw-prose-invert-bold:#fff;
  --tw-prose-invert-counters:#9ca3af;
  --tw-prose-invert-bullets:#4b5563;
  --tw-prose-invert-hr:#374151;
  --tw-prose-invert-quotes:#f3f4f6;
  --tw-prose-invert-quote-borders:#374151;
  --tw-prose-invert-captions:#9ca3af;
  --tw-prose-invert-code:#fff;
  --tw-prose-invert-pre-code:#d1d5db;
  --tw-prose-invert-pre-bg:rgba(0, 0, 0, .5);
  --tw-prose-invert-th-borders:#4b5563;
  --tw-prose-invert-td-borders:#374151;
  font-size:1rem;
  line-height:1.75
}
.prose :where(p):not(:where([class~=not-prose] *)) {
  margin-bottom:1.25em;
  margin-top:1.25em
}
.prose :where(video):not(:where([class~=not-prose] *)) {
  margin-bottom:2em;
  margin-top:2em
}
.prose :where(figure):not(:where([class~=not-prose] *)) {
  margin-bottom:2em;
  margin-top:2em
}
.prose :where(li):not(:where([class~=not-prose] *)) {
  margin-bottom:.5em;
  margin-top:.5em
}
.prose :where(ol>li):not(:where([class~=not-prose] *)) {
  padding-left:.375em
}
.prose :where(ul>li):not(:where([class~=not-prose] *)) {
  padding-left:.375em
}
.prose :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.75em;
  margin-top:.75em
}
.prose :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.25em
}
.prose :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.25em
}
.prose :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.25em
}
.prose :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.25em
}
.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~=not-prose] *)) {
  margin-bottom:.75em;
  margin-top:.75em
}
.prose :where(hr+*):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose :where(h2+*):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose :where(h3+*):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose :where(h4+*):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose :where(thead th:first-child):not(:where([class~=not-prose] *)) {
  padding-left:0
}
.prose :where(thead th:last-child):not(:where([class~=not-prose] *)) {
  padding-right:0
}
.prose :where(tbody td, tfoot td):not(:where([class~=not-prose] *)) {
  padding:.5714286em
}
.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~=not-prose] *)) {
  padding-left:0
}
.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~=not-prose] *)) {
  padding-right:0
}
.prose :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-sm :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.5714286em;
  margin-top:.5714286em
}
.prose-sm :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.1428571em
}
.prose-sm :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.1428571em
}
.prose-sm :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.1428571em
}
.prose-sm :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.1428571em
}
.prose-sm :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose-sm :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-base :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.75em;
  margin-top:.75em
}
.prose-base :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.25em
}
.prose-base :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.25em
}
.prose-base :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.25em
}
.prose-base :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.25em
}
.prose-base :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose-base :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-lg :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.8888889em;
  margin-top:.8888889em
}
.prose-lg :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.3333333em
}
.prose-lg :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.3333333em
}
.prose-lg :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.3333333em
}
.prose-lg :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.3333333em
}
.prose-lg :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose-lg :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.8em;
  margin-top:.8em
}
.prose-xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.2em
}
.prose-xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.2em
}
.prose-xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.2em
}
.prose-xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.2em
}
.prose-xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose-xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-2xl :where(.prose>ul>li p):not(:where([class~=not-prose] *)) {
  margin-bottom:.8333333em;
  margin-top:.8333333em
}
.prose-2xl :where(.prose>ul>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.3333333em
}
.prose-2xl :where(.prose>ul>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.3333333em
}
.prose-2xl :where(.prose>ol>li>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:1.3333333em
}
.prose-2xl :where(.prose>ol>li>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:1.3333333em
}
.prose-2xl :where(.prose>:first-child):not(:where([class~=not-prose] *)) {
  margin-top:0
}
.prose-2xl :where(.prose>:last-child):not(:where([class~=not-prose] *)) {
  margin-bottom:0
}
.prose-invert {
  --tw-prose-body:var(--tw-prose-invert-body);
  --tw-prose-headings:var(--tw-prose-invert-headings);
  --tw-prose-lead:var(--tw-prose-invert-lead);
  --tw-prose-links:var(--tw-prose-invert-links);
  --tw-prose-bold:var(--tw-prose-invert-bold);
  --tw-prose-counters:var(--tw-prose-invert-counters);
  --tw-prose-bullets:var(--tw-prose-invert-bullets);
  --tw-prose-hr:var(--tw-prose-invert-hr);
  --tw-prose-quotes:var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders:var(--tw-prose-invert-quote-borders);
  --tw-prose-captions:var(--tw-prose-invert-captions);
  --tw-prose-code:var(--tw-prose-invert-code);
  --tw-prose-pre-code:var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg:var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders:var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders:var(--tw-prose-invert-td-borders)
}
.sr-only {
  clip:rect(0, 0, 0, 0);
  border-width:0;
  height:1px;
  margin:-1px;
  overflow:hidden;
  padding:0;
  position:absolute;
  white-space:nowrap;
  width:1px
}
.pointer-events-none {
  pointer-events:none
}
.visible {
  visibility:visible
}
.invisible {
  visibility:hidden
}
.fixed {
  position:fixed
}
.absolute {
  position:absolute
}
.relative {
  position:relative
}
.sticky {
  position:sticky
}
.inset-0 {
  bottom:0;
  left:0;
  right:0;
  top:0
}
.top-10 {
  top:2rem
}
.right-0 {
  right:0
}
.right-3 {
  right:.75rem
}
.bottom-0 {
  bottom:0
}
.left-0 {
  left:0
}
.bottom-1 {
  bottom:.25rem
}
.-top-\[5px\] {
  top:-5px
}
.-right-\[5px\] {
  right:-5px
}
.right-4 {
  right:1rem
}
.bottom-4 {
  bottom:1rem
}
.bottom-20 {
  bottom:5rem
}
.top-24 {
  top:6rem
}
.top-0 {
  top:0
}
.top-1\/2 {
  top:50%
}
.left-1\/2 {
  left:50%
}
.-top-2 {
  top:-.5rem
}
.-right-1 {
  right:-.25rem
}
.top-1 {
  top:.25rem
}
.top-\[52px\] {
  top:52px
}
.-right-\[84px\] {
  right:-84px
}
.top-\[2px\] {
  top:2px
}
.top-full {
  top:100%
}
.top-14 {
  top:3.5rem
}
.bottom-\[1px\] {
  bottom:1px
}
.top-2 {
  top:.5rem
}
.right-2 {
  right:.5rem
}
.top-4 {
  top:1rem
}
.top-5 {
  top:1.2rem;
}
.top-6 {
  top:1.5rem
}
.top-8 {
  top:2rem
}
.top-12 {
  top:3rem
}
.top-13 {
  top:3.25rem
}
.top-16 {
  top:4rem
}
.top-80 {
  top:40rem
}
.-top-\[3px\] {
  top:-3px
}
.-right-\[3px\] {
  right:-3px
}
.bottom-2 {
  bottom:.5rem
}
.-top-\[2px\] {
  top:-2px
}
.top-\[1px\] {
  top:1px
}
.right-1 {
  right:.25rem
}
.-top-\[1px\] {
  top:-1px
}
.left-1 {
  left:.25rem
}
.left-2 {
  left:.5rem
}
.top-3 {
  top:.75rem
}
.left-10 {
  left:2.5rem
}
.left-30 {
  left:9.5rem
}
.-top-\[0\.5px\] {
  top:-.5px
}
.bottom-8 {
  bottom:2rem
}
.-top-4 {
  top:-1rem
}
.right-5 {
  right:1.25rem
}
.top-3\/4 {
  top:75%
}
.-right-8 {
  right:-2rem
}
.left-4 {
  left:1rem
}
.-top-10 {
  top:-2.5rem
}
.-top-12 {
  top:-3rem
}
.-top-16 {
  top:-4rem
}
.-right-9 {
  right:-2.25rem
}
.bottom-1\/4 {
  bottom:25%
}
.right-1\/2 {
  right:50%
}
.z-20 {
  z-index:20
}
.-z-10 {
  z-index:-10
}
.z-50 {
  z-index:50
}
.z-30 {
  z-index:30
}
.z-10 {
  z-index:10
}
.z-0 {
  z-index:0
}
.z-40 {
  z-index:40
}
.m-0 {
  margin:0
}
.m-auto {
  margin:auto
}
.my-8 {
  margin-bottom:2rem;
  margin-top:2rem
}
.mx-auto {
  margin-left:auto;
  margin-right:auto
}
.mx-1 {
  margin-left:.25rem;
  margin-right:.25rem
}
.my-3 {
  margin-bottom:.75rem;
  margin-top:.75rem
}
.mx-4 {
  margin-left:1rem;
  margin-right:1rem
}
.mx-8 {
  margin-left:2rem;
  margin-right:2rem
}
.mx-3 {
  margin-left:.75rem;
  margin-right:.75rem
}
.my-4 {
  margin-bottom:1rem;
  margin-top:1rem
}
.my-0 {
  margin-bottom:0;
  margin-top:0
}
.my-2 {
  margin-bottom:.5rem;
  margin-top:.5rem
}
.my-5 {
  margin-bottom:1.25rem;
  margin-top:1.25rem
}
.my-10 {
  margin-bottom:2.5rem;
  margin-top:2.5rem
}
.mx-2 {
  margin-left:.5rem;
  margin-right:.5rem
}
.mt-3 {
  margin-top:.75rem
}
.mb-2 {
  margin-bottom:.5rem
}
.mb-4 {
  margin-bottom:1rem
}
.mt-4 {
  margin-top:1rem
}
.mt-5 {
  margin-top:1.25rem
}
.mb-3 {
  margin-bottom:.75rem
}
.mr-1 {
  margin-right:.25rem
}
.mr-2 {
  margin-right:.5rem
}
.mb-0 {
  margin-bottom:0
}
.mt-10 {
  margin-top:2.5rem
}
.mt-14 {
  margin-top:3.5rem
}
.mt-2 {
  margin-top:.5rem
}
.mt-6 {
  margin-top:1.5rem
}
.ml-3 {
  margin-left:.75rem
}
.mr-3 {
  margin-right:.75rem
}
.ml-2 {
  margin-left:.5rem
}
.ml-1 {
  margin-left:.25rem
}
.ml-4 {
  margin-left:1rem
}
.mb-5 {
  margin-bottom:1.25rem
}
.-mt-12 {
  margin-top:-3rem
}
.mt-0 {
  margin-top:0
}
.mt-1 {
  margin-top:.25rem
}
.mr-6 {
  margin-right:1.5rem
}
.mt-20 {
  margin-top:5rem
}
.mr-1\.5 {
  margin-right:.375rem
}
.mr-0\.5 {
  margin-right:.125rem
}
.mr-0 {
  margin-right:0
}
.-mt-1 {
  margin-top:-.25rem
}
.mr-\[2px\] {
  margin-right:2px
}
.mb-1 {
  margin-bottom:.25rem
}
.mt-auto {
  margin-top:auto
}
.-mt-2 {
  margin-top:-.5rem
}
.-ml-\[350px\] {
  margin-left:-350px
}
.-mt-\[350px\] {
  margin-top:-350px
}
.ml-28 {
  margin-left:7rem
}
.-ml-4 {
  margin-left:-1rem
}
.mr-5 {
  margin-right:1.25rem
}
.ml-\[1px\] {
  margin-left:1px
}
.mb-6 {
  margin-bottom:1.5rem
}
.mb-8 {
  margin-bottom:2rem
}
.-mt-\[18px\] {
  margin-top:-18px
}
.mt-8 {
  margin-top:2rem
}
.-mt-4 {
  margin-top:-1rem
}
.mt-12 {
  margin-top:3rem
}
.mb-10 {
  margin-bottom:2.5rem
}
.mt-28 {
  margin-top:7rem
}
.mt-16 {
  margin-top:4rem
}
.block {
  display:block
}
.inline-block {
  display:inline-block
}
.inline {
  display:inline
}
.flex {
  display:flex
}
.inline-flex {
  display:inline-flex
}
.table {
  display:table
}
.grid {
  display:grid
}
.hidden {
  display:none
}
.aspect-square {
  aspect-ratio:1/1
}
.aspect-\[2\/1\] {
  aspect-ratio:2/1
}
.aspect-video {
  aspect-ratio:16/9
}
.h-full {
  height:100%
}
.h-56 {
  height:14rem
}
.h-8 {
  height:2rem
}
.h-0 {
  height:0
}
.h-11 {
  height:2.75rem
}
.h-16 {
  height:4rem
}
.h-2 {
  height:.5rem
}
.h-20 {
  height:5rem
}
.h-32 {
  height:8rem
}
.h-4 {
  height:1rem
}
.h-10 {
  height:2.5rem
}
.h-6 {
  height:1.5rem
}
.h-\[600px\] {
  height:600px
}
.h-screen {
  height:100vh
}
.h-3 {
  height:.75rem
}
.h-\[5px\] {
  height:5px
}
.h-12 {
  height:3rem
}
.h-7 {
  height:1.75rem
}
.h-\[700px\] {
  height:700px
}
.h-\[67px\] {
  height:67px
}
.h-\[80px\] {
  height:80px
}
.h-24 {
  height:6rem
}
.h-5 {
  height:1.25rem
}
.h-13 {
  height:3.25rem
}
.h-14 {
  height:3.5rem
}
.h-9 {
  height:2.25rem
}
.max-h-0 {
  max-height:0
}
.max-h-screen {
  max-height:100vh
}
.max-h-\[400px\] {
  max-height:400px
}
.max-h-\[1000px\] {
  max-height:1000px
}
.min-h-screen {
  min-height:100vh
}
.min-h-\[250px\] {
  min-height:250px
}
.min-h-\[16px\] {
  min-height:16px
}
.min-h-full {
  min-height:100%
}
.min-h-\[55px\] {
  min-height:55px
}
.min-h-\[48px\] {
  min-height:48px
}
.min-h-\[50vh\] {
  min-height:50vh
}
.w-full {
  width:100%
}
.w-8 {
  width:2rem
}
.w-11 {
  width:2.75rem
}
.w-16 {
  width:4rem
}
.w-2 {
  width:.5rem
}
.w-20 {
  width:5rem
}
.w-32 {
  width:8rem
}
.w-14 {
  width:3.5rem
}
.w-\[138px\] {
  width:138px
}
.w-4 {
  width:1rem
}
.w-10 {
  width:2.5rem
}
.w-\[250px\] {
  width:250px
}
.w-\[190px\] {
  width:190px
}
.w-6 {
  width:1.5rem
}
.w-5 {
  width:1.25rem
}
.w-1\/2 {
  width:50%
}
.w-12 {
  width:3rem
}
.w-7 {
  width:1.75rem
}
.w-\[700px\] {
  width:700px
}
.w-\[10px\] {
  width:10px
}
.w-\[100px\] {
  width:100px
}
.w-24 {
  width:6rem
}
.w-6\/12 {
  width:50%
}
.w-3\/12 {
  width:25%
}
.w-2\/12 {
  width:16.666667%
}
.w-\[45\%\] {
  width:45%
}
.w-3\/4 {
  width:75%
}
.w-1\/4 {
  width:25%
}
.w-9 {
  width:2.25rem
}
.w-auto {
  width:auto
}
.w-\[180px\] {
  width:180px
}
.w-\[2000px\] {
  width:2000px
}
.min-w-\[300px\] {
  min-width:300px
}
.min-w-\[120px\] {
  min-width:120px
}
.min-w-full {
  min-width:100%
}
.max-w-none {
  max-width:none
}
.max-w-md {
  max-width:28rem
}
.max-w-\[370px\] {
  max-width:370px
}
.max-w-xs {
  max-width:20rem
}
.max-w-\[1000px\] {
  max-width:1000px
}
.max-w-7xl {
  max-width:80rem
}
.max-w-lg {
  max-width:32rem
}
.max-w-sm {
  max-width:24rem
}
.max-w-\[100px\] {
  max-width:100px
}
.max-w-\[38px\] {
  max-width:38px
}
.max-w-\[140px\] {
  max-width:140px
}
.max-w-\[150px\] {
  max-width:150px
}
.max-w-\[220px\] {
  max-width:220px
}
.max-w-\[200px\] {
  max-width:200px
}
.max-w-5xl {
  max-width:64rem
}
.max-w-\[377px\] {
  max-width:377px
}
.max-w-6xl {
  max-width:72rem
}
.max-w-4xl {
  max-width:56rem
}
.max-w-3xl {
  max-width:48rem
}
.max-w-\[600px\] {
  max-width:600px
}
.max-w-\[1800px\] {
  max-width:1800px
}
.max-w-\[500px\] {
  max-width:500px
}
.flex-1 {
  flex: 1
}
.flex-none {
  flex:none
}
.shrink-0 {
  flex-shrink:0
}
.grow {
  flex-grow:1
}
.grow-0 {
  flex-grow:0
}
.table-fixed {
  table-layout:fixed
}
.-translate-y-1\/2 {
  --tw-translate-y:-50%
}
.-translate-x-1\/2, .-translate-y-1\/2 {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-translate-x-1\/2 {
  --tw-translate-x:-50%
}
.-translate-y-2 {
  --tw-translate-y:-.5rem
}
.-translate-y-2, .translate-y-2 {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.translate-y-2 {
  --tw-translate-y:.5rem
}
.translate-y-10 {
  --tw-translate-y:2.5rem
}
.-translate-y-\[1px\], .translate-y-10 {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-translate-y-\[1px\] {
  --tw-translate-y:-1px
}
.translate-y-\[1px\] {
  --tw-translate-y:1px
}
.-translate-x-4, .translate-y-\[1px\] {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-translate-x-4 {
  --tw-translate-x:-1rem
}
.translate-x-1\/2 {
  --tw-translate-x:50%
}
.rotate-180, .translate-x-1\/2 {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.rotate-180 {
  --tw-rotate:180deg
}
.rotate-90 {
  --tw-rotate:90deg
}
.-rotate-\[15deg\], .rotate-90 {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.-rotate-\[15deg\] {
  --tw-rotate:-15deg
}
.rotate-0 {
  --tw-rotate:0deg
}
.rotate-0, .scale-\[0\.85\] {
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.scale-\[0\.85\] {
  --tw-scale-x:.85;
  --tw-scale-y:.85
}
.scale-\[1\.25\] {
  --tw-scale-x:1.25;
  --tw-scale-y:1.25;
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
@keyframes fadeIn {
  0% {
      opacity:0
  }
  to {
      opacity:1
  }
}
.animate-fade-in {
  animation:fadeIn .3s cubic-bezier(.25, .46, .45, .94)
}
.animate-glow-slow-blue {
  animation:glowBlue 3s linear infinite
}
.animate-slide-down-fade-in01s {
  animation:slideDownFadeIn .1s cubic-bezier(.25, .46, .45, .94)
}
.animate-slide-down-fade-in06s {
  animation:slideDownFadeIn .6s cubic-bezier(.25, .46, .45, .94)
}
@keyframes spinSlowing {
  0% {
      transform:rotate(0)
  }
  to {
      transform:rotate(20turn)
  }
}
.animate-spin-slowing {
  animation:spinSlowing 10s cubic-bezier(.29, .37, .38, .44) infinite
}
.animate-slide-down-fade-in02s {
  animation:slideDownFadeIn .2s cubic-bezier(.25, .46, .45, .94)
}
.animate-slide-down-fade-in03s {
  animation:slideDownFadeIn .3s cubic-bezier(.25, .46, .45, .94)
}
.animate-slide-down-fade-in04s {
  animation:slideDownFadeIn .4s cubic-bezier(.25, .46, .45, .94)
}
.animate-slide-down-fade-in05s {
  animation:slideDownFadeIn .5s cubic-bezier(.25, .46, .45, .94)
}
@keyframes glowCyan {
  0% {
      text-shadow:0 0 20px #00d1ff
  }
  50% {
      text-shadow:0 0 5px #00d1ff
  }
  to {
      text-shadow:0 0 20px #00d1ff
  }
}
.animate-glow-slow-cyan {
  animation:glowCyan 3s linear infinite
}
@keyframes pulse {
  50% {
      opacity:.5
  }
}
.animate-pulse {
  animation:pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}
@keyframes slideDownFadeIn {
  0% {
      opacity:0;
      transform:translateY(-15px)
  }
  to {
      opacity:1;
      transform:translateY(0)
  }
}
.animate-slide-down-fade-in07s {
  animation:slideDownFadeIn .7s cubic-bezier(.25, .46, .45, .94)
}
@keyframes spin {
  to {
      transform:rotate(1turn)
  }
}
.animate-spin {
  animation:spin 1s linear infinite
}
@keyframes jumpBounce {
  0% {
      transform:translateY(0)
  }
  10% {
      transform:translateY(0)
  }
  30% {
      transform:translateY(-5px)
  }
  50% {
      transform:translateY(0)
  }
  57% {
      transform:translateY(-2px)
  }
  64% {
      transform:translateY(0)
  }
  to {
      transform:translateY(0)
  }
}
.animate-jump-bounce {
  animation:jumpBounce .5s linear infinite
}
.cursor-pointer {
  cursor:pointer
}
.cursor-auto {
  cursor:auto
}
.cursor-default {
  cursor:default
}
.select-none {
  -webkit-user-select:none;
  -moz-user-select:none;
  user-select:none
}
.resize-none {
  resize:none
}
.resize {
  resize:both
}
.snap-x {
  scroll-snap-type:x var(--tw-scroll-snap-strictness)
}
.snap-start {
  scroll-snap-align:start
}
.list-none {
  list-style-type:none
}
.appearance-none {
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none
}
.grid-cols-4-6 {
  grid-template-columns: 45% 55%;
}
.grid-cols-4 {
  grid-template-columns:repeat(4, minmax(0, 1fr))
}
.grid-cols-2 {
  grid-template-columns:repeat(2, minmax(0, 1fr))
}
.grid-cols-1 {
  grid-template-columns:repeat(1, minmax(0, 1fr))
}
.grid-cols-3 {
  grid-template-columns:repeat(3, minmax(0, 1fr))
}
.flex-row {
  flex-direction:row
}
.flex-col {
  flex-direction:column
}
.flex-wrap {
  flex-wrap:wrap
}
.flex-nowrap {
  flex-wrap:nowrap
}
.content-center {
  align-content:center
}
.items-end {
  align-items:flex-end
}
.items-center {
  align-items:center
}
.justify-start {
  justify-content:flex-start
}
.justify-end {
  justify-content:flex-end
}
.justify-center {
  justify-content:center
}
.justify-between {
  justify-content:space-between
}
.justify-evenly {
  justify-content:space-evenly
}
.gap-4 {
  gap:1rem
}
.gap-2 {
  gap:.5rem
}
.-space-y-8>:not([hidden])~:not([hidden]) {
  --tw-space-y-reverse:0;
  margin-bottom:calc(-2rem*var(--tw-space-y-reverse));
  margin-top:calc(-2rem*(1 - var(--tw-space-y-reverse)))
}
.-space-x-20>:not([hidden])~:not([hidden]) {
  --tw-space-x-reverse:0;
  margin-left:calc(-5rem*(1 - var(--tw-space-x-reverse)));
  margin-right:calc(-5rem*var(--tw-space-x-reverse))
}
.self-center {
  align-self:center
}
.self-stretch {
  align-self:stretch
}
.justify-self-end {
  justify-self:end
}
.overflow-hidden {
  overflow:hidden
}
.overflow-x-auto {
  overflow-x:auto
}
.overflow-y-auto {
  overflow-y:auto
}
.overflow-x-hidden {
  overflow-x:hidden
}
.overflow-y-hidden {
  overflow-y:hidden
}
.overflow-x-scroll {
  overflow-x:scroll
}
.scroll-auto {
  scroll-behavior:auto
}
.truncate {
  overflow:hidden;
  white-space:nowrap
}
.text-ellipsis, .truncate {
  text-overflow:ellipsis
}
.whitespace-normal {
  white-space:normal
}
.whitespace-nowrap {
  white-space:nowrap
}
.rounded-4 {
  border-radius:.4rem
}
.rounded-lg {
  border-radius:.5rem
}
.rounded-xl {
  border-radius:.75rem
}
.rounded-3xl {
  border-radius:1.5rem
}
.rounded-4xl {
  border-radius:2rem
}
.rounded-6xl {
  border-radius:3rem
}
.rounded-full {
  border-radius:9999px
}
.rounded-2xl {
  border-radius:1rem
}
.rounded-sm {
  border-radius:.125rem
}
.rounded-none {
  border-radius:0
}
.rounded-rt-xl {
  border-top-right-radius:.75rem
}
.rounded-lt-xl {
  border-top-left-radius:.75rem
}
.rounded-b-none {
  border-bottom-left-radius:0;
  border-bottom-right-radius:0
}
.rounded-r-full {
  border-bottom-right-radius:9999px;
  border-top-right-radius:9999px
}
.rounded-tr-none {
  border-top-right-radius:0
}
.border-2 {
  border-width:2px
}
.border-3 {
  border-width:3px
}
.border-6 {
  border-width:6px
}
.border-0 {
  border-width:0
}
.border-\[3px\] {
  border-width:3px
}
.border {
  border-width:1px
}
.border-b {
  border-bottom-width:1px
}
.border-b-none {
  border-bottom-width:0
}
.border-b-2 {
  border-bottom-width:2px
}
.border-t-2 {
  border-top-width:2px
}
.border-t {
  border-top-width:1px
}
.border-r {
  border-right-width:1px
}
.border-r-2 {
  border-right-width:2px
}
.border-l-2 {
  border-left-width:2px
}
.border-slate-100 {
  --tw-border-opacity:1;
  border-color:rgb(46 62 77/var(--tw-border-opacity))
}
.border-slate-500 {
  --tw-border-opacity:.46;
  border-color:rgb(63 139 255/var(--tw-border-opacity))
}
.border-white-60 {
  --tw-border-opacity:.6;
  border-color:rgb(255 255 255/var(--tw-border-opacity))
}
.border-transparent {
  border-color:transparent
}
.border-blue {
  --tw-border-opacity:1;
  border-color:rgb(0 133 255/var(--tw-border-opacity))
}
.border-cyan-500 {
  --tw-border-opacity:1;
  border-color:rgb(43 147 255/var(--tw-border-opacity))
}
.border-cyan-500\/28 {
  --tw-border-opacity:.28;
  border-color:rgb(43 147 255/var(--tw-border-opacity))
}
.\!border-transparent {
  border-color:transparent!important
}
.border-slate-900 {
  --tw-border-opacity:1;
  border-color:rgb(4 17 29/var(--tw-border-opacity))
}
.border-slate-400 {
  --tw-border-opacity:1;
  border-color:rgb(46 62 76/var(--tw-border-opacity))
}

.border-white {
  --tw-border-opacity:1;
  border-color:rgb(255 255 255/var(--tw-border-opacity))
}
.border-blue-500 {
  --tw-border-opacity:1;
  border-color:rgb(0 133 255/var(--tw-border-opacity))
}
.border-slate-100 {
  --tw-border-opacity:1;
  border-color:rgb(119 126 145/var(--tw-border-opacity))
}
.border-slate-600 {
  --tw-border-opacity:1;
  border-color:rgb(19 31 42/var(--tw-border-opacity))
}
.border-slate-200 {
  --tw-border-opacity:1;
  border-color:rgb(113 121 127/var(--tw-border-opacity))
}
.border-gary {
  --tw-border-opacity:1;
  border-color:rgb(151 151 151/var(--tw-border-opacity))
}
.border-blue-200 {
  --tw-border-opacity:1;
  border-color:rgb(122 192 255/var(--tw-border-opacity))
}
.border-slate-300 {
  --tw-border-opacity:1;
  border-color:rgb(64 74 83/var(--tw-border-opacity))
}
.border-b-slate-500 {
  --tw-border-opacity:1;
  border-bottom-color:rgb(46 62 77/var(--tw-border-opacity))
}
.border-b-cyan-500 {
  --tw-border-opacity:1;
  border-bottom-color:rgb(0 209 255/var(--tw-border-opacity))
}
.border-b-slate-100\/25 {
  border-bottom-color:#777e9140
}
.bg-gold {
  --tw-bg-opacity:1;
  background-color:rgb(255 226 179/var(--tw-bg-opacity))
}
.bg-white-1 {
  --tw-bg-opacity:0.1;
  background-color:rgb(255 226 179/var(--tw-bg-opacity))
}
.bg-grey-6 {
  background: #443C4A;
}
.bg-grey-radio {
  background: #171133;
}
.bg-moreInfo {
  --tw-bg-opacity:.76;
  background-color:rgb(0 59 115/var(--tw-bg-opacity))
}
.bg-register-btn {
  --tw-bg-opacity:1;
  background-color:rgb(63 139 255/var(--tw-bg-opacity))
}
.bg-register-btn-31 {
  --tw-bg-opacity:.31;
  background-color:rgb(63 139 255/var(--tw-bg-opacity))
}
.bg-slate-600 {
  --tw-bg-opacity:1;
  background-color:rgb(19 31 42/var(--tw-bg-opacity))
}
.bg-slate-800 {
  --tw-bg-opacity:1;
  background-color:rgb(4 17 29/var(--tw-bg-opacity))
}
.bg-slate-700 {
  --tw-bg-opacity:1;
  background-color:rgb(18 31 42/var(--tw-bg-opacity))
}
.bg-slate-900\/90 {
  background-color:#04111de6
}
.bg-transparent {
  background-color:transparent
}
.\!bg-img-transparent {
  background:transparent !important
}
.bg-\[\#061222\] {
  background-color: #061222;
}
.bg-lightblue{
  --tw-bg-opacity:1;
  background-color:rgb(0 55 138/var(--tw-bg-opacity))
}
.bg-lightblue-500{
  background-color: #103150;
}
.bg-lightblue\/15{
  --tw-bg-opacity:.15;
  background-color:rgb(0 55 138/var(--tw-bg-opacity))
}
.bg-blue-500 {
  --tw-bg-opacity:1;
  background-color:rgb(43 147 255/var(--tw-bg-opacity))
}
.bg-blue-500-15 {
  --tw-bg-opacity:.15;
  background-color:rgb(43 147 255/var(--tw-bg-opacity))
}
.bg-green-500 {
  --tw-bg-opacity:1;
  background-color:rgb(0 234 105/var(--tw-bg-opacity))
}
.bg-red-500 {
  --tw-bg-opacity:1;
  background-color:rgb(255 69 69/var(--tw-bg-opacity))
}
.bg-black {
  --tw-bg-opacity:1;
  background-color:rgb(0 0 0/var(--tw-bg-opacity))
}
.bg-slate-900 {
  --tw-bg-opacity:1;
  background-color:rgb(4 17 29/var(--tw-bg-opacity))
}
.bg-red {
  --tw-bg-opacity:1;
  background-color:rgb(255 69 69/var(--tw-bg-opacity))
}
.bg-slate-700\/90 {
  background-color:#121f2ae6
}
.bg-cyan-500 {
  --tw-bg-opacity:1;
  background-color:rgb(0 209 255/var(--tw-bg-opacity))
}
.\!bg-black {
  --tw-bg-opacity:1!important;
  background-color:rgb(0 0 0/var(--tw-bg-opacity))!important
}
.\!bg-black\/80 {
  background-color:#000c!important
}
.bg-white\/40 {
  background-color:#fff6
}
.bg-green-600 {
  --tw-bg-opacity:1;
  background-color:rgb(0 178 80/var(--tw-bg-opacity))
}
.bg-blue-400 {
  --tw-bg-opacity:1;
  background-color:rgb(41 153 255/var(--tw-bg-opacity))
}
.bg-gray-600 {
  --tw-bg-opacity:1;
  background-color:rgb(75 85 99/var(--tw-bg-opacity))
}
.bg-black\/95 {
  background-color:#000000f2
}
.bg-white {
  --tw-bg-opacity:1;
  background-color:rgb(255 255 255/var(--tw-bg-opacity))
}
.bg-slate-800\/80 {
  background-color:#04111dcc
}
.bg-blue-500\/80 {
  background-color:#0085ffcc
}
.bg-orange-500\/80 {
  background-color:#f97316cc
}
.bg-slate-500 {
  --tw-bg-opacity:1;
  background-color:rgb(46 62 77/var(--tw-bg-opacity))
}
.bg-blue-600 {
  --tw-bg-opacity:1;
  background-color:rgb(0 104 199/var(--tw-bg-opacity))
}
.bg-slate-900\/80 {
  background-color:#04111dcc
}
.bg-blue-700 {
  --tw-bg-opacity:1;
  background-color:rgb(12 72 127/var(--tw-bg-opacity))
}
.bg-white\/80 {
  background-color:#fffc
}
.bg-yellow-300 {
  --tw-bg-opacity:1;
  background-color:rgb(253 224 71/var(--tw-bg-opacity))
}
.bg-red-600 {
  --tw-bg-opacity:1;
  background-color:rgb(255 13 13/var(--tw-bg-opacity))
}
.bg-red-700 {
  --tw-bg-opacity:1;
  background-color:rgb(212 0 0/var(--tw-bg-opacity))
}
.bg-slate-300 {
  --tw-bg-opacity:1;
  background-color:rgb(110 110 110/var(--tw-bg-opacity))
}
.bg-blue-300 {
  --tw-bg-opacity:1;
  background-color:rgb(82 172 255/var(--tw-bg-opacity))
}
.bg-\[\#1973E8\] {
  --tw-bg-opacity:1;
  background-color:rgb(25 115 232/var(--tw-bg-opacity))
}
.bg-\[\#1DA1F2\] {
  --tw-bg-opacity:1;
  background-color:rgb(29 161 242/var(--tw-bg-opacity))
}
.bg-slate-500\/50 {
  background-color:#2e3e4d80
}
.bg-black\/90 {
  background-color:#000000e6
}
.bg-orange-400 {
  --tw-bg-opacity:1;
  background-color:rgb(251 146 60/var(--tw-bg-opacity))
}
.bg-purple-500 {
  --tw-bg-opacity:1;
  background-color:rgb(168 85 247/var(--tw-bg-opacity))
}
.bg-pink-500 {
  --tw-bg-opacity:1;
  background-color:rgb(236 72 153/var(--tw-bg-opacity))
}
.bg-slate-700\/70 {
  background-color:#121f2ab3
}
.bg-none {
  background-image:none
}
.bg-gradient-to-r {
  background-image:linear-gradient(to right, var(--tw-gradient-stops))
}
.bg-gradient-to-l {
  background-image:linear-gradient(to left, var(--tw-gradient-stops))
}
.bg-rainbow-pastel {
  background-image:url(../../asstes/img/gradient-pastel-rainbow.png)
}
.bg-gradient-to-b {
  background-image:linear-gradient(to bottom, var(--tw-gradient-stops))
}
.from-black\/80 {
  --tw-gradient-from:rgba(0, 0, 0, .8);
  --tw-gradient-to:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to)
}
.from-yellow-400 {
  --tw-gradient-from:#facc15;
  --tw-gradient-to:rgba(250, 204, 21, 0);
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to)
}

.from-gold-100 {
  --tw-gradient-from:#FFFBB3;
  --tw-gradient-to:rgba(250, 204, 21, 0);
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to)
}
.to-transparent {
  --tw-gradient-to:transparent
}
.to-orange-500 {
  --tw-gradient-to:#f97316
}
.to-gold-500 {
  --tw-gradient-to:#FFB684
}
.bg-clip-text {
  -webkit-background-clip:text;
  background-clip:text
}
.object-cover {
  -o-object-fit:cover;
  object-fit:cover
}
.p-3 {
  padding:.75rem
}
.p-0 {
  padding:0
}
.p-2 {
  padding:.5rem
}
.p-\[1px\] {
  padding:1px
}
.p-1 {
  padding:.25rem
}
.p-4 {
  padding:1rem
}
.\!p-1 {
  padding:.25rem!important
}
.p-\[3px\] {
  padding:3px
}
.p-\[5px\] {
  padding:5px
}
.p-5 {
  padding:1.25rem
}
.px-4 {
  padding-left:1rem;
  padding-right:1rem
}
.py-1 {
  padding-bottom:.25rem;
  padding-top:.25rem
}
.py-3 {
  padding-bottom:.75rem;
  padding-top:.75rem
}
.px-3 {
  padding-left:.75rem;
  padding-right:.75rem
}
.px-5 {
  padding-left:1.25rem;
  padding-right:1.25rem
}
.px-2 {
  padding-left:.5rem;
  padding-right:.5rem
}
.py-4 {
  padding-bottom:1rem;
  padding-top:1rem
}
.py-5 {
  padding-bottom:1.25rem;
  padding-top:1.25rem
}
.\!py-4 {
  padding-bottom:1rem!important;
  padding-top:1rem!important
}
.py-7 {
  padding-bottom:1.75rem;
  padding-top:1.75rem
}
.py-2 {
  padding-bottom:.5rem;
  padding-top:.5rem
}
.px-7 {
  padding-left:1.75rem;
  padding-right:1.75rem
}
.py-8 {
  padding-bottom:2rem;
  padding-top:2rem
}
.px-6 {
  padding-left:1.5rem;
  padding-right:1.5rem
}
.px-8 {
  padding-left:2rem;
  padding-right:2rem
}
.px-10 {
  padding-left:2.5rem;
  padding-right:2.5rem
}
.px-12 {
  padding-left:3rem;
  padding-right:3rem
}
.px-15 {
  padding-left:3.5rem;
  padding-right:3.5rem
}
.\!px-6 {
  padding-left:1.5rem!important;
  padding-right:1.5rem!important
}
.px-1 {
  padding-left:.25rem;
  padding-right:.25rem
}
.py-\[2px\] {
  padding-bottom:2px;
  padding-top:2px
}
.py-6 {
  padding-bottom:1.5rem;
  padding-top:1.5rem
}
.py-0 {
  padding-bottom:0;
  padding-top:0
}
.py-10 {
  padding-bottom:2.5rem;
  padding-top:2.5rem
}
.px-0 {
  padding-left:0;
  padding-right:0
}
.pl-3 {
  padding-left:.75rem
}
.pr-0 {
  padding-right:0
}
.pr-4 {
  padding-right:1.5rem
}
.pr-6 {
  padding-right:1rem
}
.pt-20 {
  padding-top:5rem
}
.pt-3 {
  padding-top:.75rem
}
.pr-16 {
  padding-right:4rem
}
.pt-12 {
  padding-top:3rem
}
.pb-16 {
  padding-bottom:4rem
}
.pb-2 {
  padding-bottom:.5rem
}
.pr-3 {
  padding-right:.75rem
}
.pb-3 {
  padding-bottom:.75rem
}
.pt-8 {
  padding-top:2rem
}
.pt-2 {
  padding-top:.5rem
}
.pb-1 {
  padding-bottom:.25rem
}
.pt-4 {
  padding-top:1rem
}
.pt-1 {
  padding-top:.25rem
}
.pl-4 {
  padding-left:1rem
}
.pr-1 {
  padding-right:.25rem
}
.pt-0 {
  padding-top:0
}
.pb-5 {
  padding-bottom:1.25rem
}
.pt-10 {
  padding-top:2.5rem
}
.pt-16 {
  padding-top:4rem
}
.pl-2 {
  padding-left:.5rem
}
.pl-6 {
  padding-left:1.5rem
}
.pr-5 {
  padding-right:1.25rem
}
.pr-6 {
  padding-right:1.5rem
}
.pb-8 {
  padding-bottom:2rem
}
.pb-0 {
  padding-bottom:0
}
.pr-2 {
  padding-right:.5rem
}
.pb-10 {
  padding-bottom:2.5rem
}
.pb-4 {
  padding-bottom:1rem
}
.pt-6 {
  padding-top:1.5rem
}
.pb-14 {
  padding-bottom:3.5rem
}
.pt-14 {
  padding-top:3.5rem
}
.pt-32 {
  padding-top:8rem
}
.pb-20 {
  padding-bottom:5rem
}
.pt-5 {
  padding-top:1.25rem
}
.text-left {
  text-align:left
}
.text-center {
  text-align:center
}
.text-right {
  text-align:right
}
.align-middle {
  vertical-align:middle
}
.align-bottom {
  vertical-align:bottom
}
.text-sm {
  font-size:.875rem;
  line-height:1.25rem
}
.text-2xl {
  font-size:1.5rem;
  line-height:2rem
}
.text-base {
  font-size:1rem;
  line-height:1.5rem
}
.text-xs {
  font-size:.75rem;
  line-height:1rem
}
.text-3xl {
  font-size:1.875rem;
  line-height:2.25rem
}
.text-xl {
  font-size:1.25rem;
  line-height:1.75rem
}
.text-\[28px\] {
  font-size:28px
}
.text-lg {
  font-size:1.125rem;
  line-height:1.75rem
}
.text-4xl {
  font-size:2.25rem;
  line-height:2.5rem
}
.text-\[22px\] {
  font-size:22px
}
.text-\[10px\] {
  font-size:10px
}
.text-\[12px\] {
  font-size:12px
}
.text-5xl {
  font-size:3.5rem;
  line-height:1
}
.text-6xl {
  font-size:3.75rem;
  line-height:1
}
.text-\[16px\] {
  font-size:16px
}
.text-\[42px\] {
  font-size:42px
}
.font-bold {
  font-weight:700
}
.font-normal {
  font-weight:400
}
.uppercase {
  text-transform:uppercase
}
.normal-case {
  text-transform:none
}
.leading-4 {
  line-height:1rem
}
.leading-tight {
  line-height:1.25
}
.lh-3 {
  line-height: 3rem !important;
}
.tracking-widest {
  letter-spacing:.1em
}
.tracking-wider {
  letter-spacing:.05em
}
.tracking-normal {
  letter-spacing:0
}
.text-blue-500 {
  --tw-text-opacity:1;
  color:rgb(0 133 255/var(--tw-text-opacity))
}
.text-skyblue{
  color: #3CC9FF;
}
.text-white\/60 {
  color:#fff9
}
.text-slate-100 {
  --tw-text-opacity:1;
  color:rgb(119 126 145/var(--tw-text-opacity))
}
.text-white {
  --tw-text-opacity:1;
  color:rgb(255 255 255/var(--tw-text-opacity))
}
.text-cyan-500 {
  --tw-text-opacity:1;
  color:rgb(58 138 255/var(--tw-text-opacity))
}
.text-slate-200 {
  --tw-text-opacity:1;
  color:rgb(113 121 127/var(--tw-text-opacity))
}
.text-red-600 {
  --tw-text-opacity:1;
  color:rgb(255 13 13/var(--tw-text-opacity))
}
.text-white\/90 {
  color:#ffffffe6
}
.text-red {
  --tw-text-opacity:1;
  color:rgb(255 69 69/var(--tw-text-opacity))
}
.text-red-\[C43434\] {
  color: #C43434;
}
.text-yellow-\[FDE23B\] {
  color: #FDE23B;
}
.text-blue-400 {
  --tw-text-opacity:1;
  color:rgb(41 153 255/var(--tw-text-opacity))
}
.text-blue {
  color:#3A8AFF
}
.text-error-red {
  color:#E02222
}
.text-green {
  --tw-text-opacity:1;
  color:rgb(0 234 105/var(--tw-text-opacity))
}
.text-gray-300 {
  --tw-text-opacity:1;
  color:rgb(209 213 219/var(--tw-text-opacity))
}
.text-gold {
  --tw-text-opacity:1;
  color:rgb(255 226 179/var(--tw-text-opacity))
}
.text-black {
  --tw-text-opacity:1;
  color:rgb(0 0 0/var(--tw-text-opacity))
}
.text-black\/80 {
  --tw-text-opacity:.8;
  color:rgb(0 0 0/var(--tw-text-opacity))
}
.text-black\/60 {
  --tw-text-opacity:.6;
  color:rgb(0 0 0/var(--tw-text-opacity))
}
.text-blue {
  --tw-text-opacity:1;
  color:rgb(0 133 255/var(--tw-text-opacity))
}
.text-slate-800 {
  --tw-text-opacity:1;
  color:rgb(4 17 29/var(--tw-text-opacity))
}
.text-green-500 {
  --tw-text-opacity:1;
  color:rgb(0 234 105/var(--tw-text-opacity))
}
.text-slate-900 {
  --tw-text-opacity:1;
  color:rgb(4 17 29/var(--tw-text-opacity))
}
.text-slate-500 {
  --tw-text-opacity:1;
  color:rgb(46 62 77/var(--tw-text-opacity))
}
.text-red-500 {
  --tw-text-opacity:1;
  color:rgb(255 69 69/var(--tw-text-opacity))
}
.text-transparent {
  color:transparent
}
.text-gray-400 {
  --tw-text-opacity:1;
  color:rgb(156 163 175/var(--tw-text-opacity))
}
.text-slate-300 {
  --tw-text-opacity:1;
  color:rgb(64 74 83/var(--tw-text-opacity))
}
.opacity-0 {
  opacity:0
}
.opacity-50 {
  opacity:.5
}
.opacity-100 {
  opacity:1
}
.opacity-40 {
  opacity:.4
}
.opacity-80 {
  opacity:.8
}
.opacity-90 {
  opacity:.9
}
.\!opacity-100 {
  opacity:1!important
}
.opacity-70 {
  opacity:.7
}
.shadow-2xl {
  --tw-shadow:0 25px 50px -12px rgba(0, 0, 0, .25);
  --tw-shadow-colored:0 25px 50px -12px var(--tw-shadow-color)
}
.shadow, .shadow-2xl {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow {
  --tw-shadow:0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored:0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color)
}
.shadow-xl {
  --tw-shadow:0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1);
  --tw-shadow-colored:0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color)
}
.shadow-md, .shadow-xl {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-md {
  --tw-shadow:0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored:0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color)
}
.shadow-sm {
  --tw-shadow:0 1px 2px 0 rgba(0, 0, 0, .05);
  --tw-shadow-colored:0 1px 2px 0 var(--tw-shadow-color)
}
.shadow-none, .shadow-sm {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-none {
  --tw-shadow:0 0 #0000;
  --tw-shadow-colored:0 0 #0000
}
.shadow-\[0_0_2px_0\] {
  --tw-shadow:0 0 2px 0;
  --tw-shadow-colored:0 0 2px 0 var(--tw-shadow-color)
}
.shadow-\[0_0_2px_0\], .shadow-lg {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-lg {
  --tw-shadow:0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored:0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
}
.shadow-\[0_0_5px_0\] {
  --tw-shadow:0 0 5px 0;
  --tw-shadow-colored:0 0 5px 0 var(--tw-shadow-color)
}
.shadow-\[0_0_5px_0\], .shadow-\[0_1px_0_0\] {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-\[0_1px_0_0\] {
  --tw-shadow:0 1px 0 0;
  --tw-shadow-colored:0 1px 0 0 var(--tw-shadow-color)
}
.shadow-\[0_0_10px_0\] {
  --tw-shadow:0 0 10px 0;
  --tw-shadow-colored:0 0 10px 0 var(--tw-shadow-color)
}
.shadow-\[0_0_10px_0\], .shadow-\[0_0_15px_0\] {
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.shadow-\[0_0_15px_0\] {
  --tw-shadow:0 0 15px 0;
  --tw-shadow-colored:0 0 15px 0 var(--tw-shadow-color)
}
.shadow-slate-900\/30 {
  --tw-shadow-color:rgba(4, 17, 29, .3);
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-cyan-500 {
  --tw-shadow-color:#2B93FF;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-black {
  --tw-shadow-color:#000;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-white-60 {
  --tw-shadow-color:rgba(255, 255, 255, 0.6);
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-slate-500 {
  --tw-shadow-color:#2e3e4d;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-blue-100 {
  --tw-shadow-color:#3F8BFF;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-blue-100\/50 {
  --tw-shadow-color:#3f8cff75;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-blue-500 {
  --tw-shadow-color:#0085ff;
  --tw-shadow:var(--tw-shadow-colored)
}
.shadow-blue-200 {
  --tw-shadow-color:#7ac0ff;
  --tw-shadow:var(--tw-shadow-colored)
}
.outline-none {
  outline:2px solid transparent;
  outline-offset:2px
}
.outline-0 {
  outline-width:0
}
.blur {
  --tw-blur:blur(8px)
}
.blur, .drop-shadow-xl {
  filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.drop-shadow-xl {
  --tw-drop-shadow:drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08))
}
// .drop-shadow-\[0_0_5px_rgba\(0\, 209\, 255\, 0\.5\)\] {
//   --tw-drop-shadow:drop-shadow(0 0 5px rgba(0, 209, 255, .5))
// }
// .drop-shadow-\[0_0_5px_rgba\(0\, 209\, 255\, 0\.5\)\], .filter {
//   filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
// }
.backdrop-blur-md {
  --tw-backdrop-blur:blur(12px)
}
.backdrop-blur-md, .backdrop-blur-sm {
  -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}
.backdrop-blur-sm {
  --tw-backdrop-blur:blur(4px)
}
.transition-all {
  transition-duration:.15s;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.transition {
  transition-duration:.15s;
  transition-property:color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.transition-opacity {
  transition-duration:.15s;
  transition-property:opacity;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.transition-colors {
  transition-duration:.15s;
  transition-property:color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property:color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.transition-\[transform\, opacity\] {
  transition-duration:.15s;
  transition-property:transform, opacity;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.transition-transform {
  transition-duration:.15s;
  transition-property:transform;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.delay-200 {
  transition-delay:.2s
}
.delay-500 {
  transition-delay:.5s
}
.delay-700 {
  transition-delay:.7s
}
.duration-100 {
  transition-duration:.1s
}
.duration-200 {
  transition-duration:.2s
}
.duration-500 {
  transition-duration:.5s
}
.duration-300 {
  transition-duration:.3s
}
.duration-150 {
  transition-duration:.15s
}
.duration-1000 {
  transition-duration:1s
}
.duration-700 {
  transition-duration:.7s
}
.ease-out {
  transition-timing-function:cubic-bezier(0, 0, .2, 1)
}
input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance:none;
  margin:0
}
.text-shadow-xs {
  text-shadow:1px 1px 0 var(--tw-shadow-color), -1px 1px 0 var(--tw-shadow-color), 1px -1px 0 var(--tw-shadow-color), -1px -1px 0 var(--tw-shadow-color)
}
.text-shadow-cyan {
  text-shadow:#00d1ff 0 0 3px
}
.before\:absolute:before {
  content:var(--tw-content);
  position:absolute
}
.before\:relative:before {
  content:var(--tw-content);
  position:relative
}
.before\:bottom-0:before {
  bottom:0;
  content:var(--tw-content)
}
.before\:left-0:before {
  content:var(--tw-content);
  left:0
}
.before\:top-0:before {
  content:var(--tw-content);
  top:0
}
.before\:right-0:before {
  content:var(--tw-content);
  right:0
}
.before\:top-1\/2:before {
  content:var(--tw-content);
  top:50%
}
.before\:left-1\/2:before {
  content:var(--tw-content);
  left:50%
}
.before\:-z-10:before {
  content:var(--tw-content);
  z-index:-10
}
.before\:z-20:before {
  content:var(--tw-content);
  z-index:20
}
.before\:z-10:before {
  content:var(--tw-content);
  z-index:10
}
.before\:inline-block:before {
  content:var(--tw-content);
  display:inline-block
}
.before\:hidden:before {
  content:var(--tw-content);
  display:none
}
.before\:h-full:before {
  content:var(--tw-content);
  height:100%
}
.before\:h-10:before {
  content:var(--tw-content);
  height:2.5rem
}
.before\:h-4:before {
  content:var(--tw-content);
  height:1rem
}
.before\:w-full:before {
  content:var(--tw-content);
  width:100%
}
.before\:w-10:before {
  content:var(--tw-content);
  width:2.5rem
}
.before\:w-1:before {
  content:var(--tw-content);
  width:.25rem
}
.before\:w-96:before {
  content:var(--tw-content);
  width:24rem
}
.before\:shrink-0:before {
  content:var(--tw-content);
  flex-shrink:0
}
.before\:-translate-y-1\/2:before {
  --tw-translate-y:-50%
}
.before\:-translate-x-1\/2:before, .before\:-translate-y-1\/2:before {
  content:var(--tw-content);
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.before\:-translate-x-1\/2:before {
  --tw-translate-x:-50%
}
@keyframes smallPing {
  75% {
      content:var(--tw-content);
      opacity:0;
      transform:scale(1.3)
  }
  to {
      content:var(--tw-content);
      opacity:0;
      transform:scale(1.5)
  }
}
.before\:animate-small-ping:before {
  animation:smallPing 1s linear infinite;
  content:var(--tw-content)
}
.before\:snap-start:before {
  content:var(--tw-content);
  scroll-snap-align:start
}
.before\:rounded-full:before {
  border-radius:9999px;
  content:var(--tw-content)
}
.before\:\!bg-black:before {
  --tw-bg-opacity:1!important;
  background-color:rgb(0 0 0/var(--tw-bg-opacity))!important;
  content:var(--tw-content)
}
.before\:bg-slate-900\/90:before {
  background-color:#04111de6;
  content:var(--tw-content)
}
.before\:bg-slate-900\/20:before {
  background-color:#04111d33;
  content:var(--tw-content)
}
.before\:bg-slate-600:before {
  --tw-bg-opacity:1;
  background-color:rgb(19 31 42/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.before\:bg-red-600:before {
  --tw-bg-opacity:1;
  background-color:rgb(255 13 13/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.before\:bg-black:before {
  --tw-bg-opacity:1;
  background-color:rgb(0 0 0/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.before\:bg-black-vert-gradient:before {
  background-image:linear-gradient(180deg, rgba(0, 16, 31, 0), rgba(0, 16, 31, .8) 20%, #00101f);
  content:var(--tw-content)
}
.before\:bg-gradient-to-r:before {
  background-image:linear-gradient(to right, var(--tw-gradient-stops));
  content:var(--tw-content)
}
.before\:bg-gradient-to-l:before {
  background-image:linear-gradient(to left, var(--tw-gradient-stops));
  content:var(--tw-content)
}
.before\:from-black\/80:before {
  --tw-gradient-from:rgba(0, 0, 0, .8);
  --tw-gradient-to:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);
  content:var(--tw-content)
}
.before\:from-transparent:before {
  --tw-gradient-from:transparent;
  --tw-gradient-to:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);
  content:var(--tw-content)
}
.before\:to-transparent:before {
  --tw-gradient-to:transparent;
  content:var(--tw-content)
}
.before\:to-slate-900:before {
  --tw-gradient-to:#04111d;
  content:var(--tw-content)
}
.before\:opacity-0:before {
  content:var(--tw-content);
  opacity:0
}
.before\:opacity-100:before {
  content:var(--tw-content);
  opacity:1
}
.before\:opacity-10:before {
  content:var(--tw-content);
  opacity:.1
}
.before\:backdrop-blur-md:before {
  --tw-backdrop-blur:blur(12px);
  -webkit-backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter:var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  content:var(--tw-content)
}
.before\:transition-all:before {
  content:var(--tw-content);
  transition-duration:.15s;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.before\:duration-500:before {
  content:var(--tw-content);
  transition-duration:.5s
}
.before\:duration-200:before {
  content:var(--tw-content);
  transition-duration:.2s
}
.after\:absolute:after {
  content:var(--tw-content);
  position:absolute
}
.after\:top-\[2px\]:after {
  content:var(--tw-content);
  top:2px
}
.after\:left-\[2px\]:after {
  content:var(--tw-content);
  left:2px
}
.after\:left-0:after {
  content:var(--tw-content);
  left:0
}
.after\:top-1\/2:after {
  content:var(--tw-content);
  top:50%
}
.after\:bottom-0:after {
  bottom:0;
  content:var(--tw-content)
}
.after\:top-0:after {
  content:var(--tw-content);
  top:0
}
.after\:z-10:after {
  content:var(--tw-content);
  z-index:10
}
.after\:z-20:after {
  content:var(--tw-content);
  z-index:20
}
.after\:-mt-\[2px\]:after {
  content:var(--tw-content);
  margin-top:-2px
}
.after\:flex:after {
  content:var(--tw-content);
  display:flex
}
.after\:hidden:after {
  content:var(--tw-content);
  display:none
}
.after\:h-5:after {
  content:var(--tw-content);
  height:1.25rem
}
.after\:h-full:after {
  content:var(--tw-content);
  height:100%
}
.after\:h-\[3px\]:after {
  content:var(--tw-content);
  height:3px
}
.after\:h-1:after {
  content:var(--tw-content);
  height:.25rem
}
.after\:h-2\/3:after {
  content:var(--tw-content);
  height:66.666667%
}
.after\:w-5:after {
  content:var(--tw-content);
  width:1.25rem
}
.after\:w-full:after {
  content:var(--tw-content);
  width:100%
}
.after\:w-96:after {
  content:var(--tw-content);
  width:24rem
}
.after\:translate-y-0:after {
  --tw-translate-y:0px
}
.after\:translate-y-0:after, .after\:translate-y-3:after {
  content:var(--tw-content);
  transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.after\:translate-y-3:after {
  --tw-translate-y:.75rem
}
.after\:rounded-full:after {
  border-radius:9999px;
  content:var(--tw-content)
}
.after\:rounded-lg:after {
  border-radius:.5rem;
  content:var(--tw-content)
}
.after\:rounded-t-full:after {
  border-top-left-radius:9999px;
  border-top-right-radius:9999px;
  content:var(--tw-content)
}
.after\:border:after {
  border-width:1px;
  content:var(--tw-content)
}
.after\:border-gray-300:after {
  --tw-border-opacity:1;
  border-color:rgb(209 213 219/var(--tw-border-opacity));
  content:var(--tw-content)
}
.after\:bg-white:after {
  --tw-bg-opacity:1;
  background-color:rgb(255 255 255/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-slate-600:after {
  --tw-bg-opacity:1;
  background-color:rgb(19 31 42/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-gray-200:after {
  --tw-bg-opacity:1;
  background-color:rgb(229 231 235/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-red-700:after {
  --tw-bg-opacity:1;
  background-color:rgb(212 0 0/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-blue-500:after {
  --tw-bg-opacity:1;
  background-color:rgb(0 133 255/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-slate-900:after {
  --tw-bg-opacity:1;
  background-color:rgb(4 17 29/var(--tw-bg-opacity));
  content:var(--tw-content)
}
.after\:bg-gradient-to-r:after {
  background-image:linear-gradient(to right, var(--tw-gradient-stops));
  content:var(--tw-content)
}
.after\:bg-gradient-to-t:after {
  background-image:linear-gradient(to top, var(--tw-gradient-stops));
  content:var(--tw-content)
}
.after\:from-slate-900:after {
  --tw-gradient-from:#04111d;
  --tw-gradient-to:rgba(4, 17, 29, 0);
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);
  content:var(--tw-content)
}
.after\:from-black:after {
  --tw-gradient-from:#000;
  --tw-gradient-to:transparent;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to);
  content:var(--tw-content)
}
.after\:to-transparent:after {
  --tw-gradient-to:transparent;
  content:var(--tw-content)
}
.after\:opacity-40:after {
  content:var(--tw-content);
  opacity:.4
}
.after\:shadow-lg:after {
  --tw-shadow:0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored:0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  content:var(--tw-content)
}
.after\:shadow-black:after {
  --tw-shadow-color:#000;
  --tw-shadow:var(--tw-shadow-colored);
  content:var(--tw-content)
}
.after\:transition-all:after {
  content:var(--tw-content);
  transition-duration:.15s;
  transition-property:all;
  transition-timing-function:cubic-bezier(.4, 0, .2, 1)
}
.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content)
}

.first\:mt-3:first-child {
  margin-top: .75rem
}

.first\:rounded-t-3xl:first-child {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem
}

.last\:rounded-b-3xl:last-child {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem
}

.last\:border-b-0:last-child {
  border-bottom-width: 0
}

.last-of-type\:col-span-2:last-of-type,
.last\:odd\:col-span-2:nth-child(odd):last-child {
  grid-column: span 2/span 2
}

.last-of-type\:hidden:last-of-type {
  display: none
}

.last-of-type\:border-none:last-of-type {
  border-style: none
}

.border-none {
  border: none;
}

.autofill\:bg-slate-600:-webkit-autofill {
  --tw-bg-opacity: 1;
  background-color: rgb(19 31 42/var(--tw-bg-opacity))
}

.autofill\:bg-slate-600:autofill {
  --tw-bg-opacity: 1;
  background-color: rgb(19 31 42/var(--tw-bg-opacity))
}

.autofill\:bg-blue-500:-webkit-autofill {
  --tw-bg-opacity: 1;
  background-color: rgb(43 147 255/var(--tw-bg-opacity))
}

.autofill\:bg-blue-500:autofill {
  --tw-bg-opacity: 1;
  background-color: rgb(43 147 255/var(--tw-bg-opacity))
}

.empty\:hidden:empty {
  display: none
}

.hover\:z-40:hover {
  z-index: 40
}

.after\:edit {
  background: url('../../asstes/partImg/edit.png') no-repeat;
}

.hover\:-translate-y-\[2px\]:hover {
  --tw-translate-y: -2px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

@keyframes glowBlue {
  0% {
      text-shadow: 0 0 20px #0085ff
  }

  50% {
      text-shadow: 0 0 5px #2999ff
  }

  to {
      text-shadow: 0 0 20px #0085ff
  }
}

.hover\:animate-glow-slow-blue:hover {
  animation: glowBlue 3s linear infinite
}

.hover\:cursor-pointer:hover {
  cursor: pointer
}

.hover\:appearance-none:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.hover\:border-cyan-200:hover {
  --tw-border-opacity: 1;
  border-color: rgb(122 231 255/var(--tw-border-opacity))
}

.hover\:bg-slate-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(19 31 42/var(--tw-bg-opacity))
}

.hover\:bg-slate-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(46 62 77/var(--tw-bg-opacity))
}

.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(41 153 255/var(--tw-bg-opacity))
}

.hover\:bg-white\/20:hover {
  background-color: #fff3
}

.hover\:bg-slate-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(4 17 29/var(--tw-bg-opacity))
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 122 55/var(--tw-bg-opacity))
}

.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 133 255/var(--tw-bg-opacity))
}

.hover\:bg-blue-400\/20:hover {
  background-color: #2999ff33
}

.hover\:bg-slate-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(18 31 42/var(--tw-bg-opacity))
}

.hover\:bg-slate-500\/75:hover {
  background-color: #2e3e4dbf
}

.hover\:bg-none:hover {
  background-image: none
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgb(41 153 255/var(--tw-text-opacity))
}

.hover\:text-cyan-500:hover {
  --tw-text-opacity: 1;
  color: rgb(23 100 255/var(--tw-text-opacity))
}

.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgb(209 213 219/var(--tw-text-opacity))
}

.hover\:text-cyan-200:hover {
  --tw-text-opacity: 1;
  color: rgb(122 231 255/var(--tw-text-opacity))
}

.hover\:opacity-90:hover {
  opacity: .9
}

.hover\:opacity-100:hover {
  opacity: 1
}

.hover\:opacity-40:hover {
  opacity: .4
}

.hover\:before\:opacity-100:hover:before {
  content: var(--tw-content);
  opacity: 1
}

.focus\:border-cyan-200:focus {
  --tw-border-opacity: 1;
  border-color: rgb(122 231 255/var(--tw-border-opacity))
}

.focus\:border-slate-200:focus {
  --tw-border-opacity: 1;
  border-color: rgb(113 121 127/var(--tw-border-opacity))
}

.focus\:bg-slate-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(46 62 77/var(--tw-bg-opacity))
}

.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(41 153 255/var(--tw-bg-opacity))
}

.focus\:bg-slate-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(4 17 29/var(--tw-bg-opacity))
}

.focus\:bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 122 55/var(--tw-bg-opacity))
}

.focus\:bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 133 255/var(--tw-bg-opacity))
}

.focus\:bg-blue-400\/20:focus {
  background-color: #2999ff33
}

.focus\:bg-slate-500\/75:focus {
  background-color: #2e3e4dbf
}

.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.active\:bg-slate-500\/75:active {
  background-color: #2e3e4dbf
}

.active\:bg-slate-500:active {
  --tw-bg-opacity: 1;
  background-color: rgb(46 62 77/var(--tw-bg-opacity))
}

.disabled\:cursor-default:disabled {
  cursor: default
}

.disabled\:bg-white\/40:disabled {
  background-color: #fff6
}

.disabled\:bg-slate-800:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(4 17 29/var(--tw-bg-opacity))
}

.disabled\:text-slate-100:disabled {
  --tw-text-opacity: 1;
  color: rgb(119 126 145/var(--tw-text-opacity))
}

.disabled\:text-white:disabled {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.disabled\:opacity-40:disabled {
  opacity: .4
}

.disabled\:opacity-60:disabled {
  opacity: .6
}

.disabled\:hover\:animate-none:hover:disabled {
  animation: none
}

.disabled\:hover\:bg-white\/40:hover:disabled {
  background-color: #fff6
}

.disabled\:hover\:bg-slate-800:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(4 17 29/var(--tw-bg-opacity))
}

.disabled\:hover\:bg-green-700:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(0 122 55/var(--tw-bg-opacity))
}

.disabled\:hover\:bg-blue-500:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(0 133 255/var(--tw-bg-opacity))
}

.disabled\:hover\:bg-slate-500\/50:hover:disabled {
  background-color: #2e3e4d80
}

.disabled\:hover\:bg-slate-600:hover:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(19 31 42/var(--tw-bg-opacity))
}


.group:hover .group-hover\:top-1\/2 {
  top: 50%
}

.group:hover .group-hover\:translate-y-0 {
  --tw-translate-y: 0px
}

.group:hover .group-hover\:-translate-y-50 {
  --tw-translate-y: 50px
}
.group:hover .group-hover\:scale-95,
.group:hover .group-hover\:translate-y-0 , .group:hover .group-hover\:-translate-y-50{
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95
}

.group:hover .group-hover\:scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.group:hover .group-hover\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219/var(--tw-border-opacity))
}

.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255/var(--tw-border-opacity))
}

.group:hover .group-hover\:border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(0 209 255/var(--tw-border-opacity))
}

.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 153 255/var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-lightblue {
  --tw-bg-opacity:1;
background-color:rgb(0 55 138/var(--tw-bg-opacity))
}


.group:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 133 255/var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 172 255/var(--tw-bg-opacity))
}

.group:hover .group-hover\:text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(43 147 255/var(--tw-text-opacity))
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity))
}

.group:hover .group-hover\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219/var(--tw-text-opacity))
}

.group:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(41 153 255/var(--tw-text-opacity))
}

.group:hover .group-hover\:opacity-80 {
  opacity: .8
}

.group:hover .group-hover\:opacity-50 {
  opacity: .5
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1
}

.peer:checked~.peer-checked\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(0 104 199/var(--tw-bg-opacity))
}

.peer:checked~.peer-checked\:after\:translate-x-full:after {
  --tw-translate-x: 100%;
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.peer:checked~.peer-checked\:after\:border-white:after {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255/var(--tw-border-opacity));
  content: var(--tw-content)
}

.peer:focus~.peer-focus\:outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.peer:focus~.peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)
}

.peer:focus~.peer-focus\:ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(82 172 255/var(--tw-ring-opacity))
}

.page-enter-active .page-enter\:-translate-y-4 {
  --tw-translate-y: -1rem
}

.page-enter-active .page-enter\:-translate-y-4,
.page-enter-active .page-enter\:-translate-y-8 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.page-enter-active .page-enter\:-translate-y-8 {
  --tw-translate-y: -2rem
}

.page-enter-active .page-enter\:-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.page-enter-active .page-enter\:opacity-0 {
  opacity: 0
}

.layout-enter-active .layout-enter\:-translate-y-4 {
  --tw-translate-y: -1rem
}

.layout-enter-active .layout-enter\:-translate-y-4,
.layout-enter-active .layout-enter\:-translate-y-8 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.layout-enter-active .layout-enter\:-translate-y-8 {
  --tw-translate-y: -2rem
}

.layout-enter-active .layout-enter\:-translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.layout-enter-active .layout-enter\:opacity-0 {
  opacity: 0
}

.input-success-active .input-success\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.input-success-active .input-success\:opacity-100 {
  opacity: 1
}

.input-error-active .input-error\:translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.input-error-active .input-error\:opacity-100 {
  opacity: 1
}

.menu-burger-active .menu-burger\:top-2 {
  top: .5rem
}

.menu-burger-active .menu-burger\:w-6 {
  width: 1.5rem
}

.menu-burger-active .menu-burger\:-rotate-45 {
  --tw-rotate: -45deg
}

.menu-burger-active .menu-burger\:-rotate-45,
.menu-burger-active .menu-burger\:rotate-45 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.menu-burger-active .menu-burger\:rotate-45 {
  --tw-rotate: 45deg
}

.menu-burger-active .menu-burger\:opacity-0 {
  opacity: 0
}

.dark .dark\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99/var(--tw-border-opacity))
}

.dark .dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81/var(--tw-bg-opacity))
}

.dark .peer:focus~.dark\:peer-focus\:ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(4 41 74/var(--tw-ring-opacity))
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}
.col-span-9{
  grid-column: span 9/span 9
}
.col-span-3{
  grid-column: span 3/span 3
}
@media (min-width:640px) {
  .sm\:mt-0 {
      margin-top: 0
  }
  .sm\:grid-cols-1 {
    grid-template-columns:repeat(1, minmax(0, 1fr))
  }
  .sm\:grid-cols-4-6 {
    grid-template-columns:45% 55%;
  }
  .sm\:hidden {
      display: none
  }

  .sm\:max-w-none {
      max-width: none
  }

  .sm\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .sm\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:flex-row {
      flex-direction: row
  }

  .sm\:justify-between {
      justify-content: space-between
  }

  .sm\:gap-4 {
      gap: 1rem
  }

  .sm\:px-7 {
      padding-left: 1.75rem;
      padding-right: 1.75rem
  }

  .sm\:\!px-8 {
      padding-left: 2rem !important;
      padding-right: 2rem !important
  }

  .sm\:px-1 {
      padding-left: .25rem;
      padding-right: .25rem
  }

  .sm\:text-base {
      font-size: 1rem;
      line-height: 1.5rem
  }

  .sm\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }

  .sm\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem
  }

  .sm\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem
  }

  .sm\:leading-none {
      line-height: 1
  }

  .sm\:last-of-type\:flex:last-of-type {
      display: flex
  }
}

@media (min-width:768px) {
  .md\:right-3 {
      right: .75rem
  }

  .md\:top-0 {
      top: 0
  }

  .md\:bottom-0 {
      bottom: 0
  }

  .md\:col-span-5 {
      grid-column: span 5/span 5
  }

  .md\:col-span-7 {
      grid-column: span 7/span 7
  }

  .md\:my-5 {
      margin-bottom: 1.25rem;
      margin-top: 1.25rem
  }

  .md\:my-1 {
      margin-bottom: .25rem;
      margin-top: .25rem
  }

  .md\:mb-4 {
      margin-bottom: 1rem
  }

  .md\:mb-0 {
    margin-bottom: 0rem
  }


  .md\:mt-1 {
      margin-top: .25rem
  }

  .md\:mb-1 {
      margin-bottom: .25rem
  }

  .md\:mb-8 {
      margin-bottom: 2rem
  }

  .md\:mt-3 {
      margin-top: .75rem
  }

  .md\:ml-0 {
      margin-left: 0
  }

  .md\:mr-3 {
      margin-right: .75rem
  }

  .md\:mb-3 {
      margin-bottom: .75rem
  }

  .md\:mt-10 {
      margin-top: 2.5rem
  }

  .md\:mb-6 {
      margin-bottom: 1.5rem
  }

  .md\:mt-5 {
      margin-top: 1.25rem
  }

  .md\:mb-10 {
      margin-bottom: 2.5rem
  }

  .md\:mt-2 {
      margin-top: .5rem
  }

  .md\:block {
      display: block
  }

  .md\:inline-block {
      display: inline-block
  }

  .md\:flex {
      display: flex
  }

  .md\:hidden {
      display: none
  }

  .md\:h-full {
      height: 100%
  }

  .md\:h-\[80vh\] {
      height: 80vh
  }

  .md\:min-h-\[20px\] {
      min-height: 20px
  }

  .md\:min-h-\[60px\] {
      min-height: 60px
  }

  .md\:w-full {
      width: 100%
  }

  .md\:w-3\/12 {
      width: 25%
  }

  .md\:w-4\/12 {
      width: 33.333333%
  }

  .md\:w-5\/12 {
      width: 41.666667%
  }

  .md\:w-\[31\%\] {
      width: 31%
  }

  .md\:w-20 {
      width: 5rem
  }

  .md\:w-\[280px\] {
      width: 280px
  }

  .md\:max-w-\[320px\] {
      max-width: 320px
  }

  .md\:max-w-\[150px\] {
      max-width: 150px
  }

  .md\:max-w-none {
      max-width: none
  }

  .md\:shrink-0 {
      flex-shrink: 0
  }

  .md\:grow-0 {
      flex-grow: 0
  }

  .md\:scale-125 {
      --tw-scale-x: 1.25;
      --tw-scale-y: 1.25;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .md\:grid-cols-12 {
      grid-template-columns: repeat(12, minmax(0, 1fr))
  }

  .md\:flex-row {
      flex-direction: row
  }

  .md\:items-end {
      align-items: flex-end
  }

  .md\:items-center {
      align-items: center
  }

  .md\:justify-start {
      justify-content: flex-start
  }

  .md\:gap-3 {
      gap: .75rem
  }

  .md\:gap-8 {
      gap: 2rem
  }

  .md\:gap-4 {
      gap: 1rem
  }

  .md\:overflow-hidden {
      overflow: hidden
  }

  .md\:bg-slate-900\/90 {
      background-color: #04111de6
  }

  .md\:bg-white\/10 {
      background-color: #ffffff1a
  }

  .md\:p-6 {
      padding: 1.5rem
  }

  .md\:px-3 {
      padding-left: .75rem;
      padding-right: .75rem
  }

  

  .md\:px-7 {
      padding-left: 1.75rem;
      padding-right: 1.75rem
  }

  .md\:px-4 {
      padding-left: 1rem;
      padding-right: 1rem
  }

  .md\:pr-4 {
    padding-right: 1rem
}

  .md\:py-4 {
      padding-bottom: 1rem;
      padding-top: 1rem
  }

  .md\:py-3 {
      padding-bottom: .75rem;
      padding-top: .75rem
  }

  .md\:px-0 {
      padding-left: 0;
      padding-right: 0
  }

  .md\:px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem
  }

  .md\:pt-6 {
      padding-top: 1.5rem
  }

  .md\:pb-4 {
      padding-bottom: 1rem
  }

  .md\:pl-0 {
      padding-left: 0
  }

  .md\:pt-24 {
      padding-top: 6rem
  }

  .md\:pt-20 {
      padding-top: 5rem
  }

  .md\:pt-10 {
      padding-top: 2.5rem
  }

  .md\:text-center {
      text-align: center
  }

  .md\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem
  }

  .md\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }

  .md\:text-base {
      font-size: 1rem;
      line-height: 1.5rem
  }

  .md\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem
  }

  .md\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem
  }

  .md\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem
  }

  .md\:text-5xl {
      font-size: 3rem;
      line-height: 1
  }

  .md\:shadow-sm {
      --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
      --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
  }

  .md\:shadow-slate-500 {
      --tw-shadow-color: #2e3e4d;
      --tw-shadow: var(--tw-shadow-colored)
  }

  .md\:backdrop-blur-md {
      --tw-backdrop-blur: blur(12px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
      backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .md\:before\:block:before {
      content: var(--tw-content);
      display: block
  }

  .md\:before\:hidden:before {
      content: var(--tw-content);
      display: none
  }

  .md\:last-of-type\:col-auto:last-of-type {
      grid-column: auto
  }

  .md\:last-of-type\:hidden:last-of-type {
      display: none
  }

  .md\:hover\:scale-\[0\.9\]:hover {
      --tw-scale-x: .9;
      --tw-scale-y: .9
  }

  .md\:hover\:scale-\[0\.9\]:hover,
  .md\:hover\:scale-\[1\.3\]:hover {
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .md\:hover\:scale-\[1\.3\]:hover {
      --tw-scale-x: 1.3;
      --tw-scale-y: 1.3
  }
}

@media (min-width:1024px) {
  .lg\:screnHeight{
    height: calc(100vh - 15rem);
    overflow: hidden;
    // border: 4px solid #2B93FF;
    border-radius: 20px;
    // background-color: linear-gradient(rgba(30,56,76,72), rgba(76,113,145,0));
  }
  .group:hover .lg\:group-hover\:-translate-y-50 {
    --tw-translate-y: 50px
  }
  .group:hover .lg\:group-hover\:-translate-y-50{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }
  .lg\:screnHeight-10{
    height: calc(100vh - 10rem);
  }
  .lg\:static {
      position: static
  }

  .lg\:absolute {
      position: absolute
  }

  .lg\:sticky {
      position: sticky
  }

  .lg\:bottom-20 {
      bottom: 5rem
  }

  .lg\:-right-25 {
      right: -2.5rem
  }

  .lg\:top-2 {
      top: .5rem
  }

  .lg\:right-2 {
      right: .5rem
  }

  .lg\:top-3 {
      top: .75rem
  }

  .lg\:right-3 {
      right: .75rem
  }

  .lg\:top-10 {
      top: 2.5rem
  }

  .lg\:left-2 {
      left: .5rem
  }

  .lg\:left-3 {
      left: .75rem
  }

  .lg\:top-4 {
      top: 1rem
  }

  .lg\:top-16 {
      top: 4rem
  }

  .lg\:bottom-\[20\%\] {
      bottom: 20%
  }

  .lg\:bottom-\[11\%\] {
      bottom: 11%
  }

  .lg\:left-0 {
      left: 0
  }

  .lg\:top-0 {
      top: 0
  }

  .lg\:top-20 {
      top: 5rem
  }

  .lg\:order-3 {
      order: 3
  }

  .lg\:order-2 {
      order: 2
  }

  .lg\:order-1 {
      order: 1
  }

  .lg\:mx-3 {
      margin-left: .75rem;
      margin-right: .75rem
  }

  .lg\:my-0 {
      margin-bottom: 0;
      margin-top: 0
  }

  .lg\:mx-0 {
      margin-left: 0;
      margin-right: 0
  }

  .lg\:mx-5 {
      margin-left: 1.25rem;
      margin-right: 1.25rem
  }

  .lg\:mt-0 {
      margin-top: 0
  }

  .lg\:ml-auto {
      margin-left: auto
  }

  .lg\:mt-6 {
      margin-top: 1.5rem
  }

  .lg\:mr-4 {
      margin-right: 1rem
  }

  .lg\:mr-3 {
      margin-right: .75rem
  }

  .lg\:mb-8 {
      margin-bottom: 2rem
  }

  .lg\:mb-10 {
      margin-bottom: 2.5rem
  }

  .lg\:ml-10 {
      margin-left: 2.5rem
  }

  .lg\:mt-16 {
      margin-top: 4rem
  }

  .lg\:mt-3 {
      margin-top: .75rem
  }

  .lg\:mt-12 {
      margin-top: 3rem
  }

  .lg\:block {
      display: block
  }

  .lg\:inline-block {
      display: inline-block
  }

  .lg\:flex {
      display: flex
  }

  .lg\:grid {
      display: grid
  }

  .lg\:hidden {
      display: none
  }

  .lg\:h-full {
      height: 100%
  }

  .lg\:h-8 {
      height: 2rem
  }

  .lg\:h-9 {
      height: 2.25rem
  }

  .lg\:h-\[80px\] {
      height: 80px
  }

  .lg\:h-\[93px\] {
    height: 93px
}

  .lg\:h-32 {
      height: 8rem
  }

  .lg\:h-10 {
      height: 2.5rem
  }

  .lg\:h-44 {
      height: 11rem
  }

  .lg\:h-\[78\%\] {
      height: 78%
  }

  .lg\:min-h-0 {
      min-height: 0
  }

  .lg\:w-\[165px\] {
      width: 165px
  }

  .lg\:w-\[296px\] {
      width: 296px
  }
  .lg\:w-\[220px\] {
    width: 220px
}

  .lg\:w-auto {
      width: auto
  }

  .lg\:w-9 {
      width: 2.25rem
  }

  .lg\:w-\[120px\] {
      width: 120px
  }

  .lg\:w-\[110px\] {
    width: 110px
}

  .lg\:w-32 {
      width: 8rem
  }

  .lg\:w-\[23\%\] {
      width: 23%
  }

  .lg\:w-14 {
      width: 3.5rem
  }

  .lg\:w-10 {
      width: 2.5rem
  }

  .lg\:w-44 {
      width: 11rem
  }

  .lg\:w-full {
      width: 100%
  }

  .lg\:w-1\/4 {
      width: 25%
  }

  .lg\:w-3\/4 {
      width: 75%
  }

  .lg\:w-3 {
      width: .75rem
  }

  .lg\:max-w-\[240px\] {
      max-width: 240px
  }

  .lg\:max-w-none {
      max-width: none
  }

  .lg\:max-w-\[110px\] {
      max-width: 110px
  }

  .lg\:max-w-\[315px\] {
      max-width: 315px
  }

  .lg\:grow {
      flex-grow: 1
  }

  .lg\:grow-0 {
      flex-grow: 0
  }

  .lg\:grid-cols-3 {
      grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .lg\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:flex-row {
      flex-direction: row
  }

  .lg\:flex-wrap {
      flex-wrap: wrap
  }

  .lg\:items-center {
      align-items: center
  }

  .lg\:items-stretch {
      align-items: stretch
  }

  .lg\:justify-start {
      justify-content: flex-start
  }

  .lg\:justify-end {
      justify-content: flex-end
  }

  .lg\:justify-center {
      justify-content: center
  }

  .lg\:justify-between {
      justify-content: space-between
  }

  .lg\:gap-4 {
      gap: 1rem
  }

  .lg\:gap-6 {
      gap: 1.5rem
  }

  .lg\:self-start {
      align-self: flex-start
  }

  .lg\:self-end {
      align-self: flex-end
  }

  .lg\:self-center {
      align-self: center
  }

  .lg\:self-stretch {
      align-self: stretch
  }

  .lg\:overflow-x-visible {
      overflow-x: visible
  }

  .lg\:overflow-y-visible {
      overflow-y: visible
  }

  .lg\:whitespace-nowrap {
      white-space: nowrap
  }

  .lg\:rounded-3xl {
      border-radius: 1.5rem
  }

  .lg\:rounded-2xl {
      border-radius: 1rem
  }

  .lg\:rounded-rt-2xl {
    border-top-right-radius: 1rem
  }

  .lg\:rounded-lt-2xl {
    border-top-left-radius:1rem
  }

  .lg\:border-0 {
      border-width: 0
  }

  .lg\:border-b {
      border-bottom-width: 1px
  }

  .lg\:border-l {
      border-left-width: 1px
  }

  .lg\:border-cyan-500 {
      --tw-border-opacity: 1;
      border-color: rgb(0 209 255/var(--tw-border-opacity))
  }

  .lg\:border-white\/30 {
      border-color: #ffffff4d
  }

  .lg\:border-slate-400 {
      --tw-border-opacity: 1;
      border-color: rgb(46 62 76/var(--tw-border-opacity))
  }

  .lg\:bg-slate-900 {
      --tw-bg-opacity: 1;
      background-color: rgb(4 17 29/var(--tw-bg-opacity))
  }

  .lg\:bg-slate-900\/80 {
      background-color: #04111dcc
  }

  .lg\:\!bg-transparent {
      background-color: transparent !important
  }

  .lg\:bg-gray-800 {
      --tw-bg-opacity: 1;
      background-color: rgb(31 41 55/var(--tw-bg-opacity))
  }

  .lg\:bg-transparent {
      background-color: transparent
  }

  .lg\:p-1 {
      padding: .25rem
  }

  .lg\:px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem
  }

  .lg\:py-4 {
      padding-bottom: 1rem;
      padding-top: 1rem
  }

  .lg\:px-2 {
      padding-left: .5rem;
      padding-right: .5rem
  }

  .lg\:px-8 {
      padding-left: 6.25rem;
      padding-right: 6.25rem
  }

  .lg\:py-1 {
      padding-bottom: .25rem;
      padding-top: .25rem
  }

  .lg\:px-4 {
      padding-left: 1rem;
      padding-right: 1rem
  }

  .lg\:px-7 {
      padding-left: 1.75rem;
      padding-right: 1.75rem
  }

  .lg\:py-5 {
      padding-bottom: 1.25rem;
      padding-top: 1.25rem
  }

  .lg\:py-0 {
      padding-bottom: 0;
      padding-top: 0
  }

  .lg\:px-3 {
      padding-left: .75rem;
      padding-right: .75rem
  }

  .lg\:py-\[6px\] {
      padding-bottom: 6px;
      padding-top: 6px
  }

  .lg\:px-0 {
      padding-left: 0;
      padding-right: 0
  }

  .lg\:pr-1 {
      padding-right: .25rem
  }

  .lg\:pb-0 {
      padding-bottom: 0
  }

  .lg\:pl-4 {
      padding-left: 1rem
  }

  .lg\:pt-2 {
      padding-top: .5rem
  }

  .lg\:pr-6 {
      padding-right: 1.5rem
  }

  .lg\:pl-1 {
      padding-left: .25rem
  }

  .lg\:pb-5 {
      padding-bottom: 1.25rem
  }

  .lg\:pt-6 {
      padding-top: 1.5rem
  }

  .lg\:pt-0 {
      padding-top: 0
  }

  .lg\:text-left {
      text-align: left
  }

  .lg\:align-middle {
      vertical-align: middle
  }

  .lg\:text-xs {
      font-size: .75rem;
      line-height: 1rem
  }

  .lg\:text-base {
      font-size: 1.5rem;
      line-height: 1.8rem
  }

  .lg\:text-xl {
      font-size: 1.25rem;
      line-height: 1.75rem
  }

  .lg\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }

  .lg\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem
  }

  .lg\:text-2-5xl {
    font-size: 1.6rem;
  }

  .lg\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem
  }

  .lg\:text-3xl {
      font-size: 1.875rem;
      line-height: 2.25rem
  }

  .lg\:font-normal {
      font-weight: 400
  }

  .lg\:tracking-wider {
      letter-spacing: .05em
  }

  .lg\:backdrop-blur-md {
      --tw-backdrop-blur: blur(12px);
      -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
      backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
  }

  .lg\:before\:hidden:before {
      content: var(--tw-content);
      display: none
  }

  .lg\:last-of-type\:flex:last-of-type {
      display: flex
  }

  .lg\:hover\:scale-\[1\.03\]:hover {
      --tw-scale-x: 1.03;
      --tw-scale-y: 1.03;
      transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  }

  .lg\:hover\:bg-blue-700:hover {
      --tw-bg-opacity: 1;
      background-color: rgb(12 72 127/var(--tw-bg-opacity))
  }

  .lg\:hover\:text-white\/90:hover {
      color: #ffffffe6
  }

  .group:hover .lg\:group-hover\:bg-blue-400 {
      --tw-bg-opacity: 1;
      background-color: rgb(41 153 255/var(--tw-bg-opacity))
  }

  .layout-enter-active .layout-enter\:lg\:h-0,
  .page-enter-active .page-enter\:lg\:h-0 {
      height: 0
  }
}

@media (min-width:1280px) {
  .xl\:-mt-2 {
      margin-top: -.5rem
  }

  .xl\:mt-16 {
      margin-top: 4rem
  }

  .xl\:mt-24 {
      margin-top: 6rem
  }

  .xl\:-ml-10 {
      margin-left: -2.5rem
  }

  .xl\:hidden {
      display: none
  }

  .xl\:max-w-\[280px\] {
      max-width: 280px
  }

  .xl\:max-w-\[44px\] {
      max-width: 44px
  }

  .xl\:justify-start {
      justify-content: flex-start
  }

  .xl\:rounded-2xl {
      border-radius: 1rem
  }

  .xl\:p-2 {
      padding: .5rem
  }

  .xl\:px-2 {
      padding-left: .5rem;
      padding-right: .5rem
  }

  .xl\:px-3 {
      padding-left: .75rem;
      padding-right: .75rem
  }

  .xl\:pr-\[330px\] {
      padding-right: 330px
  }

  .xl\:pr-\[300px\] {
      padding-right: 300px
  }

  .xl\:text-base {
      font-size: 1rem;
      line-height: 1.5rem
  }

  .xl\:text-lg {
      font-size: 1.125rem;
      line-height: 1.75rem
  }

  .xl\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }

  .xl\:text-2xl {
      font-size: 1.5rem;
      line-height: 2rem
  }

  .xl\:leading-none {
      line-height: 1
  }

  .xl\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }
}

@media (min-width:1536px) {
  .\32xl\:w-\[18\%\] {
      width: 18%
  }

  .\32xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }

  .\32xl\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .\32xl\:justify-end {
      justify-content: flex-end
  }

  .\32xl\:p-4 {
      padding: 1rem
  }

  .\32xl\:px-3 {
      padding-left: .75rem;
      padding-right: .75rem
  }

  .\32xl\:px-6 {
      padding-left: 1.5rem;
      padding-right: 1.5rem
  }

  .\32xl\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }

  .xxl\:px-6rem66 {
    padding-left: 6.666rem;
    padding-right: 6.666rem;
  }
}

@media (min-width:1800px) {
  .\33xl\:w-\[15\.2\%\] {
      width: 15.2%
  }

  .\33xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .\33xl\:grid-cols-4 {
      grid-template-columns: repeat(4, minmax(0, 1fr))
  }

  .\33xl\:text-sm {
      font-size: .875rem;
      line-height: 1.25rem
  }
  .xxxl\:px-6rem66 {
    padding-left: 6.666rem;
    padding-right: 6.666rem;
  }
}

@media (min-width:2100px) {
  .\34xl\:w-\[11\.45\%\] {
      width: 11.45%
  }

  .\34xl\:grid-cols-8 {
      grid-template-columns: repeat(8, minmax(0, 1fr))
  }

  .\34xl\:grid-cols-5 {
      grid-template-columns: repeat(5, minmax(0, 1fr))
  }
}

@media (min-width:2600px) {
  .\35xl\:container {
      width: 100%
  }

  @media (min-width:640px) {
      .\35xl\:container {
          max-width: 640px
      }
  }

  @media (min-width:768px) {
      .\35xl\:container {
          max-width: 768px
      }
  }

  @media (min-width:1024px) {
      .\35xl\:container {
          max-width: 1024px
      }
  }

  @media (min-width:1280px) {
      .\35xl\:container {
          max-width: 1280px
      }
  }

  @media (min-width:1536px) {
      .\35xl\:container {
          max-width: 1536px
      }
  }

  @media (min-width:1800px) {
      .\35xl\:container {
          max-width: 1800px
      }
  }

  @media (min-width:2100px) {
      .\35xl\:container {
          max-width: 2100px
      }
  }

  @media (min-width:2600px) {
      .\35xl\:container {
          max-width: 2600px
      }
  }

  .\35xl\:mx-auto {
      margin-left: auto;
      margin-right: auto
  }

  .\35xl\:w-\[10\.2\%\] {
      width: 10.2%
  }

  .\35xl\:grid-cols-9 {
      grid-template-columns: repeat(9, minmax(0, 1fr))
  }

  .\35xl\:grid-cols-6 {
      grid-template-columns: repeat(6, minmax(0, 1fr))
  }

  .\35xl\:pl-0 {
      padding-left: 0
  }

  .\35xl\:pr-0 {
      padding-right: 0
  }

  .\35xl\:after\:block:after,
  .\35xl\:before\:block:before {
      content: var(--tw-content);
      display: block
  }
}

.modal-enter-from[data-v-87288abb] {
  opacity: 0
}

.modal-enter-active[data-v-87288abb],
.modal-leave-active[data-v-87288abb] {
  transition: all .2s
}

.modal-enter[data-v-87288abb],
.modal-leave-to[data-v-87288abb] {
  opacity: 0
}