
.nav-logo{
  padding: 0.375rem 0;
}
.nav-main-avatar{
  // width: 1.8rem;
}

.nav-dropdown{
  font-size: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 7.5rem;
  background-color: #021222;
  .item{
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
  .icon-ico{
    font-size: .65rem;
  }
}

.attention-info {
  // background-color: rgba(255, 255, 255, 0.05);
  
  color: rgb(255, 255, 255);
  font-family: HarmonyOS_Sans_Regular;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.1875rem;
  text-align: center;
  letter-spacing: 0px;

  .backimg {
    background-image: url('../../asstes/ohmynftimg/earnbig.jpg');
    z-index: 10000;
    background-size: cover;
  }

  span {
    color: #3A8AFF;
  }
}
