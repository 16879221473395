.slogen-Info {
  font-family: HarmonyOS_Sans_black;
  font-size: 3.25rem;
  font-weight: 1000;
  line-height: 4.875rem;
  // margin-top: 7.187rem;

  .referrals {
    display: inline-block;
    font-family: HarmonyOS_Sans_Bold;
    // font-size: 1.625rem;
    font-weight: bold;
    display: inline-block;
    background: linear-gradient(270deg, #F5DB45 0%, #F27300 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.slogen-detail {
  font-family: HarmonyOS_Sans_SC;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  text-align: justify;
}

.feature-card {
  margin-top: 1.9375rem;

  .feature-title {
    font-family: HarmonyOS_Sans_Bold;
    font-size: 52px;
    font-weight: bold;
    line-height: 52px;
    letter-spacing: 0px;
    margin-bottom: 1.125rem;
    color: #FFFFFF;
  }

  .card-List {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.card-referrals-dashborde {
  margin-top: 2.25rem;
  border: 2px dashed #4a4d57;
}

.livenow-title {
  font-family: HarmonyOS_Sans_SC_Bold;
  font-size: 2.18rem;
  font-weight: bold;
  padding: 2.25rem 0;
}

// .text-back {
//   width: 40rem;
//   height: 1200rem;
//   border: 1px solid #FFFFFF;
//   background: url(../../asstes/ohmynftimg/background.png) no-repeat, url(../../asstes/ohmynftimg/background2.png) no-repeat;
//   background-size: 100% 30rem,100% auto;
// }
