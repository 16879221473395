
.activity-page {
  min-height: 768px;

  .congratulations {
    width: '100%';
    height: '38px';
    padding-top: 72px;
    background-color: rgba(255, 255, 255, 0.05);

    .attention-info {
      width: '100%';
      height: '38px';
      color: rgb(255, 255, 255);
      font-family: AlibabaPuHuiTi-2-55-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0px;
    }
  }

  .text-title {
    margin: 72px 200px 0px 200px;
    padding-bottom: 24px;
    font-family: HarmonyOS_Sans_Bold;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: #3A8AFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  .text-list {
    margin: 0px 200px;

    .text-row {
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      height: 65px;
      font-family: HarmonyOS_Sans_Regular;
      font-size: 16px;
      font-weight: normal;
      line-height: 65px;
      color: #FFFFFF;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .text-column {
        width: 80%;
        cursor: pointer;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //溢出不换行
      }
    }
  }

  .text-detail {
    margin: 48px 200px 0px;
    font-family: HarmonyOS_Sans_Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    color: #FFFFFF;
  }
}
