.card-content-nft-card {
  border-radius: 1.5rem;
  background: #443c4a;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;

  .card-img {
    opacity: 1;
    position: relative;
    
    img {
      width: 100%;
      height: 100%;
      border-radius: 1.5rem;
    }

    .card-id {
      // min-width: 100px;
      padding: 0.5rem 1rem;
      display: inline-block;
      border-radius: 15px;
      position: absolute;
      bottom: 1rem;
      left: 1rem;
      background: #fff;
      text-align: center;

      font-family: HarmonyOS_Sans_Bold;
      font-size: 14px;
      font-weight: bold;
      color: #3E3E3E;
    }
  }

  .card-detail {
    margin-top: 1.25rem;

    .card-name-value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .card-name {
        font-family: HarmonyOS_Sans_Bold;
        // font-size: 1.25rem;
        font-weight: bold;
        // line-height: 1.25rem;
        color: #ffffff;
      }

      .card-value {
        font-family: HarmonyOS_Sans_Regular;
        // font-size: 1.25rem;
        font-weight: normal;
        // line-height: 1.25rem;
        text-align: right;
        color: #ffffff;
      }
    }
  }

  .card-busd {
    margin-top: 1rem;
    font-family: HarmonyOS_Sans_Bold;
    // font-size: 1.5rem;
    font-weight: bold;
    color: #2395FF;

    img {
      display: inline-block;
      //  width: 1.25rem;
      height: 1.25rem;
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  .card-progress {
    padding: 0.5rem 0;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    .progress-bar {
      width: 100%;
    }

    span {
      font-family: HarmonyOS_Sans_Bold;
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
      color: #3A8AFF;
    }
  }

  .card-button {
    .link {
      width: 100%;
      padding: 1rem 0rem;
      border-radius: 30px;
      opacity: 1;
      background: #3A8AFF;
      text-align: center;
      font-family: HarmonyOS_Sans_Bold;
      font-size: 1.25rem;
      font-weight: bold;
      color: #fff;
    }
  }
}
