iframe {
  display: none!important;
}

iframe[data-product="web_widget"], iframe[title]{
  display: block!important;
}

html{
  font-size: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0/var(--tw-bg-opacity));
}

@media (min-width:768px) {
  html{
    font-size: 16px;
  }
}


body{
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0/var(--tw-bg-opacity));
    width: 100%;
    min-height: 100vh;
    color: #fff;
    line-height: 1;
    background: url(./asstes/ohmynftimg/background.png) no-repeat top center;
    background-size: cover;
    background-position: top center;
}

.header-bg{
  background-image: url(./asstes/ohmynftimg/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  border-bottom: 1px solid #3F8BFF;
}

.textPage{
  *{
    font-family:Avenir;
  }
  h1{
    font-family: AvenirHeavy;
  }
  h2,strong{
    font-family: AvenirBlack;
  }
  h4{
    font-family:AvenirMedium;
  }

  &.prose :where(ol > li):not(:where([class~=not-prose] *))::marker {
    color: #fff;
    font-family: AvenirBlack;
    font-size: 1.5em;
  }
}


.hideScrollbar::-webkit-scrollbar {

  display:none
  
  }

  .border-linear{
    padding: 3px;
    background: linear-gradient(to right top , #FFDBA1, #3F8BFF);
    // clip-path: polygon(5% 0, 95% 0, 100% 5%, 100% 95%, 95% 100%, 5% 100%, 0 95%, 0 5%);
    // border-image: linear-gradient(to left , #FFDBA1, #3F8BFF) 1;
  }

  .tab-item{
    background: linear-gradient(rgba(0,73,138,0), rgba(13,60,111,24));
    border-bottom: 2px solid;
    border-color:  rgba(43,147,255,0.28);
  }

//  .uploader-noborder{
//  .ant-upload.ant-upload-select {
//     border: none !important;
//   }
//  }  

:where(.css-dev-only-do-not-override-k83k30).ant-progress.ant-progress-status-success .ant-progress-bg {
  background-color: #FDE23B;
}


.default-img.user-name-first-word {
  font-size: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
}

.uploader-noborder:where(.css-dev-only-do-not-override-k83k30).ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select{
  border: none;
}


.default-winner-img-size{
  width: 3rem;
margin: 0 .5rem;
}